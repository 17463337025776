import {
  Box,
  Button,
  Chip,
  Collapse,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import FiberNewOutlinedIcon from '@mui/icons-material/FiberNewOutlined';
import { RequestTable } from '../../commonComponent/table/SwRequestTable2';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { ChallengeFieldMap } from './ChallengeFieldMap';
import { ChallengeFieldForm } from './ChallengeFieldForm';
import {
  IFieldDetail,
  IFieldInfo,
  DefaultFieldInfo,
  DefaultFieldDetail,
} from '../../../models/field';
import {
  getFieldRequestList,
  getFieldRequestDetail,
  changeFieldApplyStatus,
} from '../../../common/api/ApiField';
import {
  KEY_FIELD_REQUEST_LIST,
  KEY_FIELD_REQUEST_DETAIL,
} from '../../../common/key';
import {
  addHyphenToPhoneno,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwCreationRequestReject } from '../../commonComponent/SwCreationRequestReject';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';

interface Props {}

const requestColumnlists = [
  '필드 이름',
  '신청일자',
  '신청자',
  '연락처',
  '상태',
  '담당자',
];
const clickColumnlist = ['필드 이름', '신청자', '신청자', '담당자'];

export const ChallengeFieldCreate: React.FC<Props> = () => {
  const queryClient = useQueryClient();
  const [commandType, setCommandType] = useState<number>(0); // 0: no-action, 1: save, 2:update, 3: delete
  const [gpsData, setGpsData] = useState<any>([]);
  const [mapReload, setMapReload] = useState(0);
  const [openRequest, setOpenRequest] = useState<boolean>(false);
  const [selectRequest, setSelectRequest] = useState<any>([]);
  const [fieldList, setFieldList] = useState<any>([]);
  const [requestList, setRequestList] = useState<any>([]);
  const [mapInfo, setMapInfo] = useState<any>();
  const [fieldDetail, setFieldDetail] =
    useState<IFieldDetail>(DefaultFieldDetail);
  const [checkRequest, setCheckRequest] = useState<boolean>(true);
  const [chosenRequest, setChosenRequest] = useState<any>([]);
  const [fieldId, setFieldId] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [fieldData, setFieldData] = useState<any>();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [registerStatus, setRegisterStatus] = useState<number>(0);
  const [rejectOpen, setRejectOpen] = useState<boolean>(false);
  const [rejectMessage, setRejectMessage] = useState<string>('');
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<any>();
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');

  const { refetch: fieldRequestDetailRefetch } = useQuery(
    KEY_FIELD_REQUEST_DETAIL,
    () => {
      console.log('fieldId:', fieldId);
      if (fieldId.length > 0) return getFieldRequestDetail(fieldId);
      return null;
    },
    {
      onSuccess: res => {
        console.log('detail : ', res);
        if (res && res.creationRequestDetail) {
          // const tmpRoute = res.creationRequestDetail.point.map((point: any) => {
          //   const temp = {
          //     lat: point.pointPtLat,
          //     lng: point.pointPtLng,
          //     radius: point.pointRadius,
          //   };
          //   return temp;
          // });
          // console.log('point :', tmpRoute);
          const tmpRoute: any = [];
          setGpsData(tmpRoute);

          const newSpot = res.creationRequestDetail.spot.map((spot: any) => {
            const temp = {
              id: spot.spotId,
              name: spot.spotName,
              image: spot.spotImage,
              lat: spot.spotPointLat,
              lng: spot.spotPointLng,
              radius: spot.spotRadius,
              description: spot.description,
              className: spot.className,
              link: spot.link,
              phoneNo: spot.phoneNo,
              address: spot.address,
            };
            return temp;
          });

          const newCourseData = {
            filedId: fieldId,
            gpsData: tmpRoute,
            spotData: newSpot,
            totalArea: res.creationRequestDetail.field.totalArea,
          };
          setFieldData(newCourseData);

          setFieldDetail(res.creationRequestDetail);
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetchDetail = (newFieldId: string) => {
    queryClient.prefetchQuery(KEY_FIELD_REQUEST_DETAIL, () =>
      getFieldRequestDetail(newFieldId)
    );
  };

  const {
    data,
    isLoading,
    refetch: fieldRequestRefetch,
  } = useQuery(
    KEY_FIELD_REQUEST_LIST,
    () => {
      console.log('checkRequest: ', checkRequest);
      if (checkRequest) return getFieldRequestList();
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        const newField =
          res &&
          res.creationRequest &&
          res.creationRequest.map((item: any) => {
            const newDate = timestamp2Localestring(item.createDate, 1000);
            const newData = [
              item.name,
              newDate,
              item.dataPortalName,
              item.dataPortalPhoneno !== null && item.dataPortalPhoneno !== ''
                ? addHyphenToPhoneno(item.dataPortalPhoneno)
                : '-',
              item.registerStatus === 2
                ? '접수요청'
                : item.registerStatus === 3
                ? '반려'
                : item.registerStatus === 1
                ? '작성중'
                : '-',
              item.editor,
            ];
            return newData;
          });
        setRequestList(newField);
        setFieldList(res ? res.creationRequest : []);
        setSelectedRow(-1);
        res.creationRequest.map((item: any, idx: number) => {
          if (item.fieldId === fieldId) {
            setSelectedRow(idx);
            prefetchDetail(fieldId);
          }
        });
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const {
    mutate: changeFieldApplyStatusMutation,
    isError: isChangeMutateError,
  } = useMutation(changeFieldApplyStatus, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage(
        registerStatus === 3 ? '수정 요청되었습니다' : '승인되었습니다.'
      );
      fieldRequestRefetch();
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `${
          registerStatus === 3 ? '수정' : '승인'
        }을 요청하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
      );
    },
  });

  const onReload = () => {
    fieldRequestRefetch();
  };

  const reloadField = (id: string) => {
    if (id !== '') {
      setFieldId(id);
      fieldRequestRefetch();
    }
  };

  useEffect(() => {
    setMapReload(1);
    return () => {
      setMapReload(0);
    };
  }, []);

  const chooseRequest = (i: number) => {
    console.log('choose index :', i);
    const Chosen = requestList[i];
    console.log('chose :', Chosen);
    const newChosen = [Chosen[0], Chosen[1], Chosen[2], Chosen[5]];
    // setIsSend(false);
    setChosenRequest([newChosen]);
    setFieldId(fieldList[i].fieldId);
    prefetchDetail(fieldList[i].fieldId);
    setSelectedRow(i);
  };

  const onRejectMessage = (message: string) => {
    if (message.length > 0) {
      setRegisterStatus(3);
      const newData = {
        fieldId: fieldId,
        status: 3, // 1-작성중 2-접수요청 3-반려 4-접수완료
        message: message,
      };
      changeFieldApplyStatusMutation(newData);
    }
  };

  const onConfirm = () => {
    setRegisterStatus(4);
    const newData = {
      fieldId: fieldId,
      status: 4, // 1-작성중 2-접수요청 3-반려 4-접수완료
      message: '',
    };
    changeFieldApplyStatusMutation(newData);
  };

  const checkDelete = () => {
    if (fieldId !== undefined && fieldId !== null && fieldId.length > 0) {
      setOpenDelete(true);
    } else {
      setOpenSnackbar(true);
      setAlertMessage('핉드를 선택해주세요');
    }
  };

  useEffect(() => {
    if (mapInfo) {
      console.log('FieldCreate mapInfo :', mapInfo);
      const newCourse = fieldDetail.field;
      const newFieldDetail = {
        field: newCourse,
        point: mapInfo.points,
      };
      console.log('FieldCreate newFieldDetail :', newFieldDetail);
      setFieldDetail(newFieldDetail);
      setGpsData(mapInfo.points);
    }
  }, [mapInfo]);

  useEffect(() => {
    if (checkRequest) {
      console.log('checkRequest !!!');
      fieldRequestRefetch();
    }
    return () => {};
  }, [checkRequest]);

  return (
    <>
      <Box>
        <Paper sx={{ cursor: 'pointer', mb: '1rem' }}>
          <Box sx={{ m: '1rem 1rem' }}>
            <Typography className='title'>
              요청 {requestList?.length}개
            </Typography>
            <TableContainer sx={{ maxHeight: '27rem' }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {requestColumnlists.map((column, idx) => (
                      <TableCell
                        key={idx.toString()}
                        sx={{ p: '.4rem .7rem !important' }}
                        className={clsx(
                          { request_head: false },
                          'request_tablehead'
                        )}
                      >
                        {column}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requestList?.map((row: any, idx: number) => (
                    <TableRow
                      key={idx.toString()}
                      onClick={() => chooseRequest(idx)}
                      className={
                        selectedRow === idx ? 'selected_row' : 'whitecolor'
                      }
                    >
                      {row.map((rowItem: any, rowIdx: number) => (
                        <TableCell
                          key={rowIdx.toString()}
                          sx={{ p: '.6rem .7rem' }}
                          className={
                            selectedRow === idx && row[3] === '접수요청'
                              ? clsx(
                                  'request_tablecell selected_row specified_row'
                                )
                              : selectedRow === idx
                              ? clsx('request_tablecell selected_row')
                              : row[3] === '접수요청'
                              ? clsx('request_tablecell specified_row')
                              : clsx('request_tablecell ')
                          }
                        >
                          {row[rowIdx]}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Paper>
        <RequestTable columns={clickColumnlist} rows={chosenRequest} />
      </Box>
      <ChallengeFieldMap
        fieldData={fieldData}
        mapInfo={mapInfo}
        setMapInfo={setMapInfo}
        reloadField={reloadField}
      />
      <ChallengeFieldForm
        commandType={commandType}
        setCommandType={setCommandType}
        mapInfo={mapInfo}
        fieldDetail={fieldDetail}
        setFieldDetail={setFieldDetail}
        onReload={onReload}
      />
      <Box className='flex_between' sx={{ m: '2rem 0' }}>
        <Button
          variant='outlined'
          color='error'
          onClick={() => window.location.reload()}
        >
          신규 작성
        </Button>
        <Box>
          <Button
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={() => {
              checkDelete();
            }}
          >
            삭제
          </Button>
          <Button
            variant='outlined'
            color='info'
            sx={{ mr: '1rem' }}
            onClick={() => setRejectOpen(true)}
          >
            수정 요청
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              onConfirm();
            }}
          >
            승인
          </Button>
        </Box>
      </Box>
      <SwConfirmDialog
        contents='필드를 삭제하시겠습니까?'
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setCommandType(3);
          setOpenDelete(false);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwCreationRequestReject
        title='필드'
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onRejectMessage={onRejectMessage}
      />
    </>
  );
};
