import { Fragment, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper, Slide, styled, Typography } from '@mui/material';
import { SwTable2 } from '../../commonComponent/table/SwTable2';
import { SwNaverMap } from '../../commonComponent/map/SwNaverMap';
import {
  timestamp2string,
  getYearList,
  getMonthList,
  commaFormat,
  timestamp2Localestring,
  getCurrentDate,
  MAX_STEP,
} from '../../../common/helper';

interface Props {
  type: any;
  subType: any;
  userInfo: any;
  dailySteps: any;
  rewardInfo: any;
}

const TitleTypography = styled(Typography)({
  padding: '.5rem .5rem .5rem .15rem',
  marginBottom: '.33rem',
  fontWeight: 500,
  fontSize: '1rem',
});

const GridRow = styled(Grid)({
  border: '1px solid #E7EDF6',
  backgroundColor: '#F9FAFC',
  borderLeft: 'none',
  padding: '.7em',
});
const GridCell = styled(Grid)({
  border: '1px solid #E7EDF6',
  borderLeft: 'none',
  padding: '.7em',
});

export const UserChallengeDetailWalkingType: React.FC<Props> = ({
  type,
  subType,
  userInfo,
  rewardInfo,
  dailySteps,
}) => {
  const a = 1;
  const [filteredSteps, setFilteredSteps] = useState<any>([]);

  function calculateMileage(step: number) {
    let mileage = 0;

    if (step >= userInfo.stepMin && step < userInfo.stepMax) {
      mileage = Math.floor(step / userInfo.stepPerPoint);
    } else if (step >= userInfo.stepMax) {
      mileage = Math.floor(userInfo.stepMax / userInfo.stepPerPoint);
    }
    return mileage;
  }

  useEffect(() => {
    console.log('-> rewardInfo:', rewardInfo);
  }, [rewardInfo]);

  useEffect(() => {
    console.log('-> userInfo:', userInfo);
  }, [userInfo]);

  useEffect(() => {
    console.log('-> dailySteps:', dailySteps);
    if (dailySteps !== undefined) {
      const newFilteredSteps: any[] = dailySteps
        .filter(
          (item: any, idx: number) =>
            getCurrentDate().localeCompare(item.date) !== -1
        )
        .sort((q: any, w: any) => {
          const newA = new Date(q.date);
          const newB = new Date(w.date);
          return newB.getTime() - newA.getTime();
        });
      setFilteredSteps(newFilteredSteps);
    }
  }, [dailySteps]);
  return (
    <>
      <Box>
        <TitleTypography>사용자 정보</TitleTypography>
        <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
          <GridRow item xs={3}>
            닉네임
          </GridRow>
          <GridCell item xs={9}>
            {userInfo.name}
          </GridCell>
          <GridRow item xs={3}>
            휴대폰 번호
          </GridRow>
          <GridCell item xs={9}>
            {userInfo.phoneno}
          </GridCell>
          {type === 1 ||
          type === 5 ||
          type === 12 ||
          type === 16 ||
          type === 18 ? (
            <>
              <GridRow item xs={3}>
                총 걸음수
              </GridRow>
              <GridCell item xs={9}>
                {type === 12 || (type === 18 && subType === 2)
                  ? commaFormat(String(userInfo.optionalProgress))
                  : commaFormat(String(userInfo.progress))}
              </GridCell>
              {(type === 12 ||
                type === 16 ||
                (type === 18 && subType === 2)) && (
                <>
                  <GridRow item xs={3}>
                    인증 건수
                  </GridRow>
                  <GridCell item xs={9}>
                    {type === 12 || (type === 18 && subType === 2)
                      ? commaFormat(String(userInfo.progress))
                      : commaFormat(String(userInfo.optionalProgress))}
                  </GridCell>
                </>
              )}
              <GridRow item xs={3}>
                목표 걸음수
              </GridRow>
              <GridCell item xs={9}>
                {commaFormat(String(userInfo.goal))}
              </GridCell>
            </>
          ) : type === 5 ? (
            <>
              <GridRow item xs={3}>
                총 누적 마일리지
              </GridRow>
              <GridCell item xs={9}>
                {commaFormat(String(userInfo.progress))}
              </GridCell>
            </>
          ) : (
            <></>
          )}

          {type === 1 ||
          type === 5 ||
          type === 12 ||
          type === 16 ||
          type === 18 ? (
            <></>
          ) : type === 6 || type === 11 || type === 17 ? (
            <>
              <GridRow item xs={3}>
                마일리지
              </GridRow>
              <GridCell item xs={9}>
                {type === 17
                  ? commaFormat(
                      String(
                        Number(userInfo.progress) +
                          Number(userInfo.optionalProgress)
                      )
                    )
                  : commaFormat(String(userInfo.progress))}
              </GridCell>
              <GridRow item xs={3}>
                마일리지 당 걸음 수
              </GridRow>
              <GridCell item xs={9}>
                {commaFormat(String(userInfo.stepPerPoint))}
              </GridCell>
              <GridRow item xs={3}>
                일일 누적 최소 걸음 수
              </GridRow>
              <GridCell item xs={9}>
                {commaFormat(String(userInfo.stepMin))}
              </GridCell>
              <GridRow item xs={3}>
                일 최대 걸음수
              </GridRow>
              <GridCell item xs={9}>
                {userInfo.stepMax === MAX_STEP
                  ? '제한없음'
                  : commaFormat(String(userInfo.stepMax))}
              </GridCell>
              {type === 17 && (
                <>
                  <GridRow item xs={3}>
                    일일 캐릭터 최대 누적 마일리지
                  </GridRow>
                  <GridCell item xs={9}>
                    {commaFormat(String(userInfo.maxPoint))}
                  </GridCell>
                </>
              )}
            </>
          ) : (
            <>
              {/* <GridRow item xs={3}>
                일 최대 걸음수
              </GridRow>
              <GridCell item xs={9}>
                {userInfo.stepMax === MAX_STEP
                  ? '제한없음'
                  : commaFormat(String(userInfo.stepMax))}
              </GridCell> */}
            </>
          )}
        </Grid>
      </Box>
      <Box sx={{ mt: '2em' }}>
        <TitleTypography>챌린지 진행 정보</TitleTypography>
        <Grid
          container
          sx={{ borderTop: '1px solid #8899B6', textAlign: 'center' }}
        >
          {type === 1 || type === 5 || (type === 18 && subType === 1) ? (
            <>
              <GridRow item xs={6}>
                날짜
              </GridRow>
              <GridRow item xs={6}>
                걸음 수
              </GridRow>
              {filteredSteps?.map((step: any, idx: number) => (
                <Fragment key={idx.toString()}>
                  <GridCell item xs={6}>
                    {step?.date}
                  </GridCell>
                  <GridCell item xs={6}>
                    {commaFormat(String(step?.step))}
                  </GridCell>
                </Fragment>
              ))}
            </>
          ) : type === 12 || (type === 18 && subType === 2) ? (
            <>
              <GridRow item xs={4}>
                날짜
              </GridRow>
              <GridRow item xs={4}>
                걸음 수
              </GridRow>
              <GridRow item xs={4}>
                인증건수
              </GridRow>
              {filteredSteps?.map((step: any, idx: number) => (
                <Fragment key={idx.toString()}>
                  <GridCell item xs={4}>
                    {step?.date}
                  </GridCell>
                  <GridCell item xs={4}>
                    {commaFormat(String(step?.step))}
                  </GridCell>
                  <GridCell item xs={4}>
                    {commaFormat(String(step?.certificateCount))}
                  </GridCell>
                </Fragment>
              ))}
            </>
          ) : type === 16 ? (
            <>
              <GridRow item xs={4}>
                날짜
              </GridRow>
              <GridRow item xs={4}>
                걸음 수
              </GridRow>
              <GridRow item xs={4}>
                청취 컨텐츠 수
              </GridRow>
              {filteredSteps?.map((step: any, idx: number) => (
                <Fragment key={idx.toString()}>
                  <GridCell item xs={4}>
                    {step?.date}
                  </GridCell>
                  <GridCell item xs={4}>
                    {commaFormat(String(step?.step))}
                  </GridCell>
                  <GridCell item xs={4}>
                    {commaFormat(String(step?.completedAudioContentCount))}
                  </GridCell>
                </Fragment>
              ))}
            </>
          ) : type === 6 || type === 11 ? (
            <>
              <GridRow item xs={4}>
                날짜
              </GridRow>
              <GridRow item xs={4}>
                걸음 수
              </GridRow>
              <GridRow item xs={4}>
                적립 마일리지
              </GridRow>
              {filteredSteps?.map((step: any, idx: number) => (
                <Fragment key={idx.toString()}>
                  <GridCell item xs={4}>
                    {step?.date}
                  </GridCell>
                  <GridCell item xs={4}>
                    {commaFormat(String(step?.step))}
                  </GridCell>
                  <GridCell item xs={4}>
                    {calculateMileage(step?.step)}
                  </GridCell>
                </Fragment>
              ))}
            </>
          ) : type === 17 ? (
            <>
              <GridRow item xs={2}>
                날짜
              </GridRow>
              <GridRow item xs={2}>
                총 마일리지
              </GridRow>
              <GridRow item xs={2}>
                걸음 수
              </GridRow>
              <GridRow item xs={2}>
                걸음 마일리지
              </GridRow>
              <GridRow item xs={2}>
                잡은 캐릭터 수
              </GridRow>
              <GridRow item xs={2}>
                캐릭터 마일리지
              </GridRow>
              {filteredSteps?.map((step: any, idx: number) => (
                <Fragment key={idx.toString()}>
                  <GridCell item xs={2}>
                    {step?.date}
                  </GridCell>
                  <GridCell item xs={2}>
                    {calculateMileage(step?.step) +
                      Number(step?.characterMileage)}
                  </GridCell>
                  <GridCell item xs={2}>
                    {commaFormat(String(step?.step))}
                  </GridCell>
                  <GridCell item xs={2}>
                    {calculateMileage(step?.step)}
                  </GridCell>
                  <GridCell item xs={2}>
                    {step?.characterCount}
                  </GridCell>
                  <GridCell item xs={2}>
                    {step?.characterMileage}
                  </GridCell>
                </Fragment>
              ))}
            </>
          ) : (
            <>
              <TitleTypography sx={{ m: '.5rem' }}>
                챌린지 콘텐츠가 미생성되었습니다.
              </TitleTypography>
            </>
          )}
        </Grid>
      </Box>
      <Box sx={{ mt: '2em' }}>
        {(type === 1 ||
          type === 5 ||
          type === 6 ||
          type === 11 ||
          type === 12 ||
          type === 18) && (
          <TitleTypography>
            리워드 응모 정보({rewardInfo.draw ? rewardInfo.draw?.length : 0}
            건)
          </TitleTypography>
        )}
        {rewardInfo.draw &&
          rewardInfo.draw.map((item: any, idx: number) => (
            <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
              <GridRow item xs={4}>
                쿠폰명
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardTitle}
              </GridCell>
              <GridRow item xs={4}>
                쿠폰 지급 형태
              </GridRow>
              <GridCell item xs={8}>
                {
                  rewardInfo.rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                }
              </GridCell>
              <GridRow item xs={4}>
                응모 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardDrawTime !== null
                  ? timestamp2Localestring(item.rewardDrawTime, 1000)
                  : '-'}
              </GridCell>
            </Grid>
          ))}
      </Box>
      <Box sx={{ mt: '2em' }}>
        {(type === 1 ||
          type === 5 ||
          type === 6 ||
          type === 11 ||
          type === 12 ||
          type === 18) && (
          <TitleTypography>
            리워드 발급 정보({rewardInfo.reward ? rewardInfo.reward?.length : 0}
            건)
          </TitleTypography>
        )}
        {rewardInfo.reward &&
          rewardInfo.reward.map((item: any, idx: number) => (
            <Grid container sx={{ borderTop: '1px solid #8899B6' }}>
              <GridRow item xs={4}>
                쿠폰명
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardTitle}
              </GridCell>
              <GridRow item xs={4}>
                쿠폰 지급 형태
              </GridRow>
              <GridCell item xs={8}>
                {
                  rewardInfo.rewardGivetype[item.rewardGiveType]
                    .challengeRewardGivetypeDescription
                }
              </GridCell>
              <GridRow item xs={4}>
                응모 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardDrawTime !== null
                  ? timestamp2Localestring(item.rewardDrawTime, 1000)
                  : '-'}
              </GridCell>
              <GridRow item xs={4}>
                발급 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardCreateTime !== null
                  ? timestamp2Localestring(item.rewardCreateTime, 1000)
                  : '-'}
              </GridCell>
              <GridRow item xs={4}>
                사용 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.rewardUsedTime !== null
                  ? timestamp2Localestring(item.rewardUsedTime, 1000)
                  : ''}
              </GridCell>
              <GridRow item xs={4}>
                양식 입력 일시
              </GridRow>
              <GridCell item xs={8}>
                {item.양식입력일시}
              </GridCell>
            </Grid>
          ))}
      </Box>
    </>
  );
};
