import {
  Box,
  Button,
  Collapse,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import React, { useState, useEffect, useRef } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { NormalTable } from '../../commonComponent/table/NormalTable';
import { SwGridTable } from '../../commonComponent/table/SwGridTable';
import { SwTable } from '../../commonComponent/table/SwTable';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { getPushList, cancelPush } from '../../../common/api/ApiPush';
import { KEY_PUSH_LIST } from '../../../common/key';
import { IPush, IPushCancel } from '../../../models/push';
import { PushUpdate } from './PushUpdate';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';

interface Props {}

const RowsCell = styled(TableCell)({
  wordWrap: 'break-word',
  whiteSpace: 'normal',
});

const columns = [
  '전송 상태',
  '분류',
  '받는 사람',
  '아이디',
  '제목',
  '내용',
  '링크',
  '적용 일시',
  '발송자',
  '내용 수정',
  '예약 취소',
];

export const AdminPush: React.FC<Props> = () => {
  const site = 'webAdmin';
  var currentDate = new Date();
  const searchRef = useRef<any>(null);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const queryClient = useQueryClient();
  const [openFilter, setOpenFilter] = useState(false);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState<string>('');
  const [searchWord, setSearchWord] = useState<string>('');
  const [pushList, setPushList] = useState<any>([]);
  const [targetPush, setTargetPush] = useState<any>(null);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openUpdateConfirm, setOpenUpdateConfirm] = useState<boolean>(false);

  const {
    data,
    isLoading,
    refetch: pushRefetch,
  } = useQuery(
    KEY_PUSH_LIST,
    () => {
      const newData = {
        site: site,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getPushList(newData);
    },
    {
      onSuccess: res => {
        console.log('push:', res.push);
        currentDate = new Date();
        setPushList(res.push);
        setTotalRecords(res.pagination.totalRecordCount);
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  const prefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_PUSH_LIST, () => {
      const newData = {
        site: site,
        startDate: rangeDate[0] === null ? 0 : rangeDate[0].getTime() / 1000,
        endDate: rangeDate[1] === null ? 0 : rangeDate[1].getTime() / 1000,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      return getPushList(newData);
    });
  };

  const { mutate: cancelPushMutate, isError: isMutateError } = useMutation(
    cancelPush,
    {
      onSuccess: () => {
        setOpenUpdateConfirm(false);
        pushRefetch();
      },
      onError: error => {
        console.log(error);
      },
    }
  );

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
    prefetch(newPage);
  };

  const cancelReservation = () => {
    const newCancel: IPushCancel = {
      id: targetPush.pushId,
      site: site,
    };
    cancelPushMutate(newCancel);
  };

  useEffect(() => {
    pushRefetch();
  }, [rangeDate]);

  return (
    <>
      <Box sx={{ m: '1.5rem 0' }} className='flex_end'>
        <Collapse orientation='horizontal' in={openFilter}>
          <SwDateRangePicker
            rangeDate={rangeDate}
            setRangeDate={setRangeDate}
          />
        </Collapse>
        <Button
          variant='outlined'
          onClick={() => setOpenFilter(!openFilter)}
          sx={{ ml: '1rem', height: '2rem' }}
        >
          <FilterAltOutlinedIcon />
          필터
        </Button>
        <TextField
          select
          defaultValue={10}
          sx={{
            ml: '1rem',
            mb: 0,
            '& .MuiOutlinedInput-root': {
              borderRadius: '4px',
              height: '2rem',
            },
          }}
        >
          <MenuItem value={5}>5개씩 보기</MenuItem>
          <MenuItem value={10}>10개씩 보기</MenuItem>
          <MenuItem value={15}>15개씩 보기</MenuItem>
          <MenuItem value={20}>20개씩 보기</MenuItem>
        </TextField>
      </Box>
      <Paper sx={{ p: '.5rem 1rem', mt: '1.5rem' }}>
        <TableContainer>
          <Table sx={{ tableLayout: 'fixed', width: '100%' }}>
            <TableHead sx={{ bgcolor: '#F9FAFC' }}>
              <TableRow>
                {columns.map((column, idx) => (
                  <TableCell key={idx.toString()} sx={{ p: '.6rem' }}>
                    {column}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {pushList?.map((row: any, idx: number) => (
                <TableRow key={row.pushId}>
                  <RowsCell>
                    {row.result.status === 0
                      ? '예정'
                      : row.result.status === 1
                      ? '전송중'
                      : '완료'}
                  </RowsCell>
                  <RowsCell>{row.receiver.type}</RowsCell>
                  <RowsCell>{row.receiver.name}</RowsCell>
                  <RowsCell>{row.receiver.id}</RowsCell>
                  <RowsCell>{row.title}</RowsCell>
                  <RowsCell>{row.content}</RowsCell>
                  <RowsCell>{row.link}</RowsCell>
                  <RowsCell>
                    {row.requestDate
                      ? timestamp2Localestring(row.requestDate)
                      : ''}
                  </RowsCell>
                  <RowsCell>{row.creator}</RowsCell>
                  <RowsCell>
                    <Button
                      id={row.pushId}
                      size='small'
                      variant='outlined'
                      color='info'
                      disabled={
                        timestamp2Localestring(row.requestDate) <
                        timestamp2Localestring(currentDate.getTime())
                      }
                      onClick={(evt: any) => {
                        setTargetPush(row);
                        setOpenConfirm(true);
                        console.log('update');
                      }}
                    >
                      내용수정
                    </Button>
                  </RowsCell>
                  <RowsCell>
                    <Button
                      id={row.pushId}
                      size='small'
                      variant='contained'
                      color='info'
                      disabled={
                        timestamp2Localestring(row.requestDate) <
                        timestamp2Localestring(currentDate.getTime())
                      }
                      onClick={(evt: any) => {
                        setTargetPush(row);
                        setOpenUpdateConfirm(true);
                      }}
                    >
                      예약취소
                    </Button>
                  </RowsCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <SwPagination
          page={page}
          handleChangePage={handleChangePage}
          count={Math.ceil(totalRecords / rowsPerPage)}
        />
      </Paper>
      <SwConfirmDialog
        contents='푸시 알림 예약을 취소하겠습니까?'
        open={openUpdateConfirm}
        onClose={() => setOpenUpdateConfirm(false)}
        onConfirm={() => cancelReservation()}
        confirm={1}
      />
      <PushUpdate
        targetPush={targetPush}
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
          pushRefetch();
        }}
      />
    </>
  );
};
