import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { DataportalConnect } from '../../commonComponent/DataportalConnect';
import { newUuidId } from '../../commonComponent/SwUuidCreate';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { MultilineTextField } from '../../styles/Styles';
import { IContract } from '../../../models/contract';
import {
  getContractList,
  deleteContract,
  postContractRegister,
} from '../../../common/api/ApiContract';

import {
  getContractStatus,
  getMemberLimit,
  getPaymentType,
  getPurposeCode,
  getPurposeCodeItems,
  timestamp2Localestring,
  timestamp2string,
} from '../../../common/helper';
import {
  KEY_WORD_PREFIX_COMPANY,
  KEY_WORD_PREFIX_CONTRACT,
} from '../../../common/key';

interface Props {
  title: string;
  portalCode: string;
  contractInfo: any;
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isEdit: () => void;
  edit: boolean;
}

const TitleTypography = styled(Typography)({
  fontWeight: 500,
  margin: '0 .5rem .7rem 0',
});

const RootPaper = styled(Paper)({
  margin: '2.8rem',
  padding: '1rem',
  borderRadius: '12px',
});

const ScrollPaper = styled(Paper)({
  maxHeight: '10rem',
  overflowY: 'scroll',
  padding: '.5rem',
  width: '44rem ',
});

const DialogDivider = styled(Divider)({
  margin: '.5rem .2rem',
});

export const CreateContract: React.FC<Props> = ({
  title,
  portalCode,
  contractInfo,
  open,
  onClose,
  onConfirm,
  edit,
  isEdit,
}) => {
  const [isDirectType, setIsDirectType] = useState<boolean>(false);
  const [addContract, setAddContract] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(-1);
  const [id, setId] = useState<string>('');
  const [contractId, setContractId] = useState<string>('');
  const [purposeCode, setPurposeCode] = useState<any>(0);
  const [password, setPassword] = useState<string>('');
  const [productType, setProductType] = useState<number>(0);
  const [price, setPrice] = useState<string>('');
  const [paymentType, setPaymentType] = useState<number>(0);
  const [memberLimit, setMemberLimit] = useState<number>(0);
  const [contractStatus, setContractStatus] = useState<number>(0);
  const [name, setName] = useState<string>('');
  const [manager, setManager] = useState<string>('');
  const [phoneno, setPhoneno] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [memo, setMemo] = useState<string>('');
  const [productDirectName, setProductDirectName] = useState<string>('');
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<string>('');
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [rangeContractDate, setRangeContractDate] = useState<any[]>([
    null,
    null,
  ]);
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openSnakerDialog, setOpenSnakerDialog] = useState<boolean>(false);

  const copyId = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (error) {
      console.log('er>', error);
    }
  };

  const { mutate: registerContractMutation, isError: isMutateError } =
    useMutation(postContractRegister, {
      onSuccess: () => {
        setOpenSnakerDialog(true);
        setDialogMessage('계약이 추가되었습니다.');
        onConfirm();
      },
      onError: error => {
        setOpenSnakerDialog(true);
        setDialogMessage(
          '계약을 추가하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const registerContract = () => {
    const newData: IContract = {
      portalCode: portalCode,
      contractId: contractId,
      purposeCode: purposeCode,
      startDate:
        rangeContractDate[0] === null
          ? 0
          : rangeContractDate[0].getTime() / 1000,
      endDate:
        rangeContractDate[1] === null
          ? 0
          : rangeContractDate[1].getTime() / 1000,
      memberLimit: memberLimit,
      memo: memo,
    };
    console.log('registerDataPortalContractMutation : ', newData);
    registerContractMutation(newData);
  };

  const checkValid = () => {
    var val = 0;

    if (contractId === null || contractId.length === 0) val = 1;
    else if (rangeContractDate[0] === null || rangeContractDate[1] === null)
      val = 2;
    else if (purposeCode === 0) val = 3;
    else if (portalCode === null || portalCode.length === 0) val = 9;

    if (val !== 0) {
      var message = '';
      switch (val) {
        case 1:
          message = '계약내역 아이디를 입력해 주세요.';
          break;
        case 2:
          message = '계약일시를 선택해 주세요.';
          break;
        case 3:
          message = '계약목적을 선택해 주세요.';
          break;
        case 9:
          message = '고객아이디를 입력해주세요.';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setDialogMessage(message);
      setOpenDialog(true);
    }

    return val;
  };

  const getCustomerUuid = () => {
    const newUuid = newUuidId();
    setContractId(newUuid);
  };

  const checkContract = (type: boolean) => {
    if (checkValid() !== 0) {
      console.log('invalid !!!');
      return;
    }
    registerContract();

    type === true ? onConfirm() : isEdit();
  };

  const resetData = () => {
    setMemberLimit(0);
    setMemo('');
    setRangeContractDate([null, null]);
    setRangeDate([null, null]);
    setPurposeCode(0);
    getCustomerUuid();
  };

  const setData = () => {
    if (contractInfo) {
      setContractId(contractInfo.contractId);

      const newRangeDate = [
        new Date(timestamp2Localestring(contractInfo.startDate, 1000)),
        new Date(timestamp2Localestring(contractInfo.endDate, 1000)),
      ];
      setRangeContractDate(newRangeDate);
      setMemberLimit(
        contractInfo.memberLimit === null ? 0 : contractInfo.memberLimit
      );
      setPurposeCode(contractInfo.purposeCode);
      setMemo(contractInfo.memo);
    }
  };

  useEffect(() => {
    console.log('CreateContract contractInfo:', contractInfo, ', edit:', edit);
    if (contractInfo) {
      setData();
    } else {
      resetData();
    }
  }, [contractInfo]);

  useEffect(() => {
    if (open) {
      if (!edit) resetData();
      else setData();
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '66vh',
            minWidth: '70vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Typography className='title'>{title}</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ p: 0, m: 0 }} />
        <DialogContent sx={{ p: '1.5rem 2.6em' }}>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
            divider={<Divider orientation='vertical' flexItem />}
            spacing={2}
            sx={{ minHeight: '55vh' }}
          >
            <Box sx={{ width: '100%' }}>
              <Box>
                <Box sx={{ p: '.5rem .15rem 0' }} className='flex_center'>
                  <Typography className='title'>계약내역 아이디</Typography>
                  <IconButton onClick={getCustomerUuid} sx={{ p: 0 }}>
                    <CachedIcon
                      sx={{ fontSize: '1.2rem', m: '-.2rem 0 0 .4rem' }}
                    />
                  </IconButton>
                </Box>
                <Box className='flex_center'>
                  <TextField
                    sx={{ width: '50%', mb: 0 }}
                    value={`${KEY_WORD_PREFIX_CONTRACT}${contractId}`}
                    InputProps={{ readOnly: true }}
                  >
                    value={`${KEY_WORD_PREFIX_CONTRACT}${contractId}`}
                  </TextField>
                  <IconButton
                    onClick={() => copyId(contractId)}
                    sx={{ p: 0, m: '.5rem' }}
                  >
                    <ContentCopyOutlinedIcon sx={{ width: '1rem' }} />
                  </IconButton>
                </Box>
                <Divider />
              </Box>
              <Box>
                <Box sx={{ p: '.5rem .15rem 0' }} className='flex_center'>
                  <Typography className='title'>고객 아이디</Typography>
                </Box>
                <Box className='flex_center'>
                  <TextField
                    sx={{ width: '50%', mb: 0 }}
                    value={`${KEY_WORD_PREFIX_COMPANY}${portalCode}`}
                    InputProps={{ readOnly: true }}
                  />
                  <IconButton
                    onClick={() => copyId(portalCode)}
                    sx={{ p: 0, m: '.5rem' }}
                  >
                    <ContentCopyOutlinedIcon sx={{ width: '1rem' }} />
                  </IconButton>
                </Box>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>계약목적</Typography>
                <TextField
                  select
                  sx={{ width: '50%' }}
                  value={purposeCode}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    setPurposeCode(Number(event.target.value));
                  }}
                >
                  <MenuItem key={0} value={0}>
                    선택
                  </MenuItem>
                  {getPurposeCodeItems().map((item: any, idx: number) => (
                    <MenuItem
                      key={idx + 1}
                      value={item === '기타' ? 99 : idx + 1}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
                <Divider />
              </Box>
              <Box>
                <Typography className='title'>계약 일시</Typography>
                <SwDateRangePicker
                  rangeDate={rangeContractDate}
                  setRangeDate={setRangeContractDate}
                />
              </Box>
              <Divider />
              <Box>
                <Typography className='title'>계약인원</Typography>
                <TextField
                  select
                  sx={{ width: '50%' }}
                  value={memberLimit}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setMemberLimit(Number(evt.target.value))}
                >
                  {getMemberLimit().map((item: any, idx: number) => (
                    <MenuItem key={idx} value={idx}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
                <DialogDivider />
              </Box>
              <Box>
                <Typography className='title'>메모</Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={5}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      height: 'auto',
                    },
                  }}
                  value={memo}
                  onChange={(
                    evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => setMemo(evt.target.value)}
                />
              </Box>
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions>
          {!edit ? (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                onClick={() => checkContract(true)}
                color='info'
                variant='contained'
              >
                저장
              </Button>
            </>
          ) : (
            <>
              <Button onClick={onClose} color='info' variant='outlined'>
                닫기
              </Button>
              <Button
                onClick={() => checkContract(false)}
                color='info'
                variant='contained'
              >
                수정
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
    </>
  );
};
