import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useResize } from '../../hooks/resize';
import withAuthentication from '../commonComponent/others/_withAuthentication';
import { Header } from './Header';
import { SideTab } from './SideTab';

interface Props {}

const Home: React.FC<Props> = () => {
  const location = useLocation();
  const { ref, isMobile } = useResize();
  const [openNav, setOpenNav] = useState<boolean>(true);
  const [param, setParam] = useState<string>('대시보드');
  const SettingParam = (newParam: string) => {
    switch (newParam) {
      case 'users':
        return '사용자 목록';
      case 'push':
        return '푸시 알림';
      case 'timeline':
        return '타임라인';
      case 'survey':
        return '질의응답 관리';
      case 'communitymanage':
        return '커뮤니티 관리';
      case 'programmanage':
        return '프로그램 관리';
      case 'contentmanage':
        return '콘텐츠 관리';
      case 'walkway':
        return '내 주변';
      case 'reviewmanagement':
        return '리뷰 요청 관리';
      case 'walkonnotice':
        return '공지사항';
      case 'errorreport':
        return '오류 보고 관리';
      // case 'accessrestrictionmanage':
      //   return '접속 제한 관리';
      // case 'inappmessage':
      //   return '인앱 메시지';
      case 'challengemanagement':
        return '챌린지 관리';
      case 'challengeField':
        return '챌린지 필드';
      case 'walkcourse':
        return '길 코스';
      case 'stamptour':
        return '스탬프 코스';
      case 'regionalfilter':
        return '위치(GPS)필터';
      case 'rewarddrawing':
        return '리워드 추첨';
      case 'portalusermanagement':
        return '포탈 계정 관리';
      case 'FAQ':
        return '자주 묻는 질문';
      case 'privateinquiry':
        return '1:1 문의';
      case 'teamnotice':
        return '워크온팀 공지사항';
      case 'portalconnectpopup':
        return '접속 안내팝업';
      case 'portalguidemanage':
        return '포털 설명서 관리';
      case 'partnermanagement':
        return '고객 관리';
      case 'supplyplan':
        return '서비스 이용권';
      case 'advertiser':
        return '광고주';
      case 'paymentmanagement':
        return '결제 관리';
      case 'empadminister':
        return '어드민 계정관리';
      case 'localstoreowner':
        return '사장님 계정 관리';
      case 'localstore':
        return '가게 관리';
      case 'localstorenotice':
        return '우리 동네 챌린지 공지사항';
      default:
        return '대시보드';
    }
  };
  useEffect(() => {
    const newParam = location.pathname.split('/')[1];
    setParam(SettingParam(newParam));
    if (isMobile()) setOpenNav(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isMobile()) setOpenNav(false);
    else setOpenNav(true);
  }, [isMobile()]);

  return (
    <div ref={ref}>
      <Header openNav={openNav} setOpenNav={setOpenNav} param={param} />
      <SideTab openNav={openNav} setOpenNav={setOpenNav} param={param} />
    </div>
  );
};

export default Home;
// export default withAuthentication(Home);
