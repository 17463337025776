import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  ChangeEvent,
  DragEvent,
  Dispatch,
  useEffect,
  useState,
  MouseEvent,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useMany } from '../../hooks/api';
import { getDataPortalAccountAmdList } from '../../common/api/ApiDataPortal';
import {
  KEY_CHALLENGE_REWARD_USER_APPLY_FORM,
  KEY_DATAPORTAL_ACCOUNT_AMD_LIST,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NONE_QUESTION,
} from '../../common/key';
import {
  getChallengeRewardUserApplyForm,
  putRewardUserApplyForm,
} from '../../common/api/ApiReward';
import { DraggablePaper } from '../styles/Styles';
import { SwSnackbar } from './views/SwSnackbar';
import { trimWhiteSpace } from '../../common/helper';
import { RESULT_OK } from '../../common/resultCode';

const draggableName = 'draggable';

const INIT_SUB_FORM = [
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
  KEY_WORD_NEXT_QUESTION,
];

interface Props {
  open: boolean;
  onClose: () => void;
  rewardId: string;
}

export const SwManageRewardApplyFormValue: React.FC<Props> = ({
  open,
  onClose,
  rewardId,
}) => {
  const [request, setRequest] = useState<boolean>(false);
  const [userId, setUserId] = useState<string>('');
  const [formList, setFormList] = useState<any[]>();
  const [formTitle, setFormTitle] = useState<any>('');
  const [formValue, setFormValue] = useState<any>('');
  const [formContent, setFormContent] = useState<any>('');
  const [formIndex, setFormIndex] = useState<number>(0);
  const [options, setOptions] = useState<any[]>([]);
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [hasSubOption, setHasSubOption] = useState<boolean>(false);
  const [subFormKey, setSubFormKey] = useState<string[]>(INIT_SUB_FORM);
  const formItems = document.querySelectorAll(`.${draggableName}`);
  const [listEvent, setListEvent] = useState<any>({
    currentDrag: null,
    over: null,
    clicked: null,
    list: [],
  });

  const makeApplyForm = (rewardApplyform: any) => {
    const applyForm =
      rewardApplyform !== undefined && rewardApplyform !== null
        ? JSON.parse(rewardApplyform)
        : [];
    const newAddedForm: any[] = [];
    const newApplyForm =
      applyForm.map !== undefined
        ? applyForm.map((item: any) => {
            const newOptions =
              item.options !== undefined && item.options.length > 0
                ? item.options.map((opt: any, i: number) => {
                    const d = { idx: i, content: opt };
                    return d;
                  })
                : [];
            item.sub_options !== undefined && item.sub_options.length > 0
              ? item.sub_options.map((opt: any, i: number) => {
                  const found = newAddedForm.filter(
                    (addFrm: any) => addFrm.title === opt.k
                  );
                  if (found === null || found.length === 0) {
                    let newOpts: any = [];
                    if (opt.type === 4) {
                      newOpts = opt.options.map((o: string) => {
                        const newData = {
                          content: o,
                        };
                        return newData;
                      });
                    }
                    newAddedForm.push({
                      type: opt.type === null ? 5 : Number(opt.type),
                      title: opt.k,
                      value: opt.v,
                      content: opt.i,
                      options: opt.type === 4 ? newOpts : opt.options,
                      hasSubOption: 0,
                      subOptions: [],
                      parentTitle: item.k,
                      parentOptionIndex: i,
                    });
                  }
                })
              : [];

            const newSubItems =
              item.sub_options !== undefined && item.sub_options.length > 0
                ? item.sub_options.map((opt: any, i: number) => opt)
                : [];
            const newSubOptions =
              item.options !== undefined && item.options.length > 0
                ? item.options.map((opt: string, i: number) => {
                    let tmpTitle = '';
                    if (newSubItems.length === 0) {
                      tmpTitle = KEY_WORD_NEXT_QUESTION;
                    } else {
                      const found = newSubItems.filter(
                        (st: any) => st.pk === opt
                      );
                      tmpTitle =
                        found !== null && found.length > 0
                          ? found[0].k
                          : KEY_WORD_NONE_QUESTION;
                    }
                    return tmpTitle;
                  })
                : [];
            const found = newSubOptions.filter(
              (opt: any) => opt !== KEY_WORD_NEXT_QUESTION
            );
            const newData = {
              type: item.type === null ? 5 : Number(item.type),
              title: item.k,
              value: item.v,
              content: item.i,
              options: newOptions,
              hasSubOption: found !== null && found.length > 0 ? 1 : 0,
              subOptions: newSubOptions,
              parentTitle: '',
              parentOptionIndex: -1,
            };
            return newData;
          })
        : [];
    setFormList([...newAddedForm, ...newApplyForm]);
  };

  const {
    data,
    isLoading,
    refetch: challengeRewardApplyFormRefetch,
  } = useQuery(
    KEY_CHALLENGE_REWARD_USER_APPLY_FORM,
    () => {
      const newData: any = {
        rewardId: rewardId,
        userId: userId,
      };
      if (request) return getChallengeRewardUserApplyForm(newData);
      return null;
    },
    {
      onSuccess: res => {
        if (
          res !== null &&
          res.applyform !== undefined &&
          res.applyform !== null
        ) {
          makeApplyForm(res.applyform.rewardApplyform);
        } else if (request) {
          setOpenSnackbar(true);
          setDialogMessage(
            '리워드 입력내용이 없습니다. 리워드 참여여부 확인해주세요'
          );
        }
      },
      onError: e => {
        console.log(e);
      },
    }
  );

  function makeNewApplyForm() {
    if (formList === undefined) return [];
    const newForm = formList
      .filter((item: any) => item.parentTitle === '')
      .map((item: any, idx: number) => {
        const newOptions =
          item.options !== undefined &&
          item.options !== null &&
          item.options.length > 0
            ? item.options.map((opt: any) => opt.content)
            : null;
        let newData: any;
        if (item.hasSubOption === 1) {
          const subOptions = item.subOptions
            .filter((subOption: string) => subOption !== KEY_WORD_NEXT_QUESTION)
            .map((subOption: string, i: number) => {
              const newSubForm = formList
                .filter((sub: any) => sub.title === subOption)
                .map((sub: any) => {
                  let newSubOptions: any;
                  if (sub.type === 4) {
                    const newOpt = sub.options.map((opt: any) => opt.content);
                    newSubOptions = {
                      type: sub.type,
                      pk: item.options[i].content,
                      k: sub.title,
                      v: sub.value,
                      i: sub.content,
                      options: newOpt,
                    };
                  } else {
                    newSubOptions = {
                      type: sub.type,
                      pk: item.options[i].content,
                      k: sub.title,
                      v: sub.value,
                      i: sub.content,
                    };
                  }
                  return newSubOptions;
                });
              return newSubForm !== null && newSubForm.length > 0
                ? newSubForm[0]
                : '';
            })
            .filter((subForm: any) => subForm !== '');
          newData =
            newOptions === null || newOptions.length === 0
              ? {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: trimWhiteSpace(item.value),
                  i: trimWhiteSpace(item.content),
                }
              : subOptions !== null && subOptions.length > 0
              ? {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: trimWhiteSpace(item.value),
                  i: trimWhiteSpace(item.content),
                  options: newOptions,
                  sub_options: subOptions,
                }
              : {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: trimWhiteSpace(item.value),
                  i: trimWhiteSpace(item.content),
                  options: newOptions,
                };
        } else {
          newData =
            newOptions !== null
              ? {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: trimWhiteSpace(item.value),
                  i: trimWhiteSpace(item.content),
                  options: newOptions,
                }
              : {
                  type: item.type,
                  k: trimWhiteSpace(item.title),
                  v: trimWhiteSpace(item.value),
                  i: trimWhiteSpace(item.content),
                };
        }
        return newData;
      });
    return newForm;
  }

  const { mutate: saveRewardUserApplyFormMutate, isError: isMutateError } =
    useMutation(putRewardUserApplyForm, {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenSnackbar(true);
          setDialogMessage('리워드 입력내용을 저장했습니다.');
        } else {
          setOpenSnackbar(true);
          setDialogMessage(`리워드 입력내용 저장 실패 : ${res.resultCodeMsg}`);
        }
      },
      onError: error => {
        setOpenSnackbar(true);
        setDialogMessage(
          '리워드 입력내용을 저장하는동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.'
        );
      },
    });

  const saveApplyForm = () => {
    const newForm = makeNewApplyForm();
    const applyForm = JSON.stringify(newForm);
    const newData: any = {
      rewardId: rewardId,
      userId: userId,
      rewardApplyform: applyForm,
    };
    saveRewardUserApplyFormMutate(newData);
  };

  const selectFormList = (newFormIndex: number) => {
    if (formList === undefined) return;
    const newFormData = formList[newFormIndex];
    setFormTitle(newFormData.title);
    setFormValue(newFormData.value !== undefined ? newFormData.value : '');
    setFormContent(newFormData.content);
    if (Number(newFormData.type) === 4) {
      setHasSubOption(newFormData.hasSubOption === 1);
      if (newFormData.hasSubOption === 1) {
        console.log('newFormData.subOptions :', newFormData.subOptions);
        setSubFormKey(newFormData.subOptions);
      } else {
        setSubFormKey(INIT_SUB_FORM);
      }
      setOptions(
        newFormData.options === undefined
          ? [{ idx: 0, content: '' }]
          : newFormData.options
      );
    }
  };

  const selectList = (
    evt: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    const newContain = !evt.currentTarget.classList.contains('click_group');
    formItems.forEach(item => item.classList.remove('click_group'));
    newContain && evt.currentTarget.classList.add('click_group');
    const newFormIndex = Number(evt.currentTarget.dataset.position);
    setFormIndex(newFormIndex);
    setListEvent({
      ...listEvent,
      clicked: newContain ? newFormIndex : null,
    });
    selectFormList(newFormIndex);
  };

  const updateFormView = () => {
    if (formList !== undefined && formValue.length > 0) {
      const newTitle = formTitle;
      const newContent = formContent;
      const newFormLists = formList;
      const newForm = formList[formIndex];
      newFormLists[formIndex] = {
        ...newForm,
        value: formValue,
      };
      setFormList(newFormLists);
    }
  };

  useEffect(() => {
    if (formValue === undefined || formValue === null) return;
    if (formValue.length > 0) updateFormView();
  }, [formValue]);

  useEffect(() => {
    setUserId('');
    if (open) setRequest(true);
    else {
      setRequest(false);
      setFormList([]);
    }
  }, [open]);

  return (
    <>
      <Dialog
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': {
            minHeight: '56vh',
            minWidth: '64vw',
          },
        }}
      >
        <DialogTitle>
          <Box className='flex_between'>
            <Typography className='title'>정보입력하기</Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogContent sx={{ p: '1rem 2rem' }}>
          <Box>
            <Typography className='title'>리워드 ID</Typography>
            <TextField
              disabled={true}
              sx={{ width: '28rem' }}
              value={rewardId}
            />
          </Box>
          <Box>
            <Typography className='title'>사용자 ID</Typography>
            <Box className='flex_start'>
              <TextField
                sx={{ width: '28rem', mr: '.5rem' }}
                value={userId}
                onChange={evt => {
                  setUserId(evt.target.value);
                }}
              />
              <Button
                variant='contained'
                color='info'
                onClick={() => {
                  challengeRewardApplyFormRefetch();
                }}
              >
                조회
              </Button>
            </Box>
            <Divider />
          </Box>
          <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            divider={
              <Divider
                orientation='vertical'
                flexItem
                sx={{ p: 0, m: '1.5rem 3rem 0' }}
              />
            }
          >
            <Box>
              {formList?.map((item: any, idx: number) => (
                <Box className='flex_between' key={`form_${idx}`}>
                  <DraggablePaper
                    className={draggableName}
                    id={idx.toString()}
                    data-position={idx}
                    sx={{ width: '25rem' }}
                    onClick={selectList}
                  >
                    <Box className='title'>
                      {Number(item.type) === 4
                        ? `객관식)${item.title}`
                        : Number(item.type) === 5
                        ? `주관식)${item.title}`
                        : item.title}
                    </Box>
                  </DraggablePaper>
                </Box>
              ))}
            </Box>
            {formList && formList.length > 0 && (
              <Box>
                <Box>
                  <Typography
                    component='div'
                    sx={{ fontSize: '1rem', m: '.5rem', mb: '1rem' }}
                  >
                    사용자가 입력한 내용
                  </Typography>
                  <TextField
                    sx={{ width: '30rem' }}
                    value={formValue}
                    onChange={(
                      evt: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                    ) => setFormValue(evt.target.value)}
                  />
                </Box>
                <Divider />
                <Box>
                  <>
                    {formList != null &&
                    Number(formList[formIndex]?.type) !== 4 ? (
                      <TextField
                        value={formContent}
                        fullWidth
                        multiline
                        rows={5}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            height: 'auto',
                            mt: '1rem',
                          },
                        }}
                        onChange={(
                          evt: ChangeEvent<
                            HTMLTextAreaElement | HTMLInputElement
                          >
                        ) => setFormContent(evt.target.value)}
                      />
                    ) : (
                      options.map((opt, idx: number) => (
                        <Box key={`option_${idx}`}>
                          <Typography
                            component='div'
                            sx={{ fontSize: '1rem', m: '.5rem', mb: '1rem' }}
                          >
                            선택지 {idx + 1}
                          </Typography>
                          <Box className='flex_start'>
                            <TextField
                              disabled={true}
                              sx={{ width: '30rem' }}
                              value={opt.content}
                              placeholder='선택지를 입력해주세요'
                            />
                          </Box>
                        </Box>
                      ))
                    )}
                  </>
                </Box>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <Divider sx={{ m: 0, p: 0 }} />
        <DialogActions>
          <Button color='info' fullWidth size='small' onClick={onClose}>
            취소
          </Button>
          <Button
            color='primary'
            fullWidth
            size='small'
            onClick={() => {
              saveApplyForm();
            }}
          >
            저장
          </Button>
        </DialogActions>
      </Dialog>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </>
  );
};
