import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddIcon from '@mui/icons-material/Add';
import CachedIcon from '@mui/icons-material/Cached';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import React, {
  ChangeEvent,
  Dispatch,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import componentGeohash from 'latlon-geohash';
import { selector, useRecoilState, useRecoilValue } from 'recoil';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation } from 'react-router';
import { debouncehook } from '../../../hooks/debouncehook';
import { SwFindLatLngbyKakaomap } from '../../commonComponent/map/SwFindLatLngbyKakaomap';
import { newUuidId, SwUuidCreate } from '../../commonComponent/SwUuidCreate';
import { SwImageSetting } from '../../commonComponent/SwImageSetting';
import { SwFileSetting } from '../../commonComponent/SwFileSetting';
import { SwSelectLocation } from '../../commonComponent/SwSelectLocation';
import { SwDateRangePicker } from '../../commonComponent/dateSetting/SwDateRangePicker';
import { DataportalConnect } from '../../commonComponent/DataportalConnect';
import {
  AddGroupTextField,
  CreatePaper,
  JoinFormPaper,
  NoneBorderChip,
  TermPaper,
} from '../../styles/Styles';
import {
  chosenCommunityState,
  communitySearchState,
} from '../../../common/atom';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwAlert } from '../../commonComponent/views/SwAlert';
import { SwConfirmDialog } from '../../commonComponent/views/SwConfirmDialog';
import { postCommunity } from '../../../common/api/ApiCommunity';
import { IUploadFile } from '../../../models/common';
import { postUploadFile } from '../../../common/api/ApiCommon';
import { DefaultCommunity, ICommunity } from '../../../models/community';
import { xapi } from '../../../hooks/session';
import { RESULT_OK } from '../../../common/resultCode';
import {
  TOOLTIP_COMMUNITY_ADDRESS_AUTH,
  TOOLTIP_COMMUNITY_JOIN_INFO,
  TOOLTIP_COMMUNITY_LOCATION,
  TOOLTIP_COMMUNITY_MEMBER_LIMIT,
  TOOLTIP_COMMUNITY_OPEN,
  TOOLTIP_COMMUNITY_POST_USE,
  TOOLTIP_COMMUNITY_RECOMMEND,
  TOOLTIP_COMMUNITY_REGION_AUTH,
  TOOLTIP_COMMUNITY_TAG,
  TOOLTIP_COMMUNITY_TYPE,
  TOOLTIP_FONT_SIZE,
  checkJoinCode,
  getRegionAmdCode,
  splitLocaitonLimit,
  trimWhiteSpace,
} from '../../../common/helper';

const MAX_JOIN_FORM_SIZE = 5;

const types = [
  { id: 0, title: '선택' },
  { id: 1, title: 'B2G' },
  { id: 2, title: 'B2B' },
  { id: 3, title: '학교' },
  { id: 5, title: '관광' },
  { id: 6, title: '홍보' },
  { id: 4, title: '기타' },
];

const typeSubs = [
  [{ id: 0, title: '선택' }],
  [
    { id: 0, title: '선택' },
    { id: 1, title: '보건소(치매안심센터, 정신건강복지센터 포함)' },
    { id: 2, title: '복지관' },
    { id: 3, title: '주민센터' },
    { id: 4, title: '체육회' },
    { id: 5, title: '기타(지역주민 대상)' },
  ],
  [
    { id: 0, title: '선택' },
    { id: 1, title: '기업' },
    { id: 2, title: '공기업' },
    { id: 3, title: '공공기관' },
    { id: 4, title: '재단·협회' },
    { id: 5, title: '병원' },
    { id: 6, title: '기타(특정인원 대상)' },
  ],
  [
    { id: 0, title: '선택' },
    { id: 1, title: '학교(초중고)' },
    { id: 2, title: '대학교' },
    { id: 3, title: '교육청' },
    { id: 4, title: '기타(학교 관련)' },
  ],
  [
    { id: 0, title: '선택' },
    { id: 1, title: '아파트' },
    { id: 2, title: '종교단체' },
    { id: 3, title: '봉사단체' },
    { id: 4, title: '워크온 제휴업체' },
    { id: 5, title: '대행사' },
    { id: 6, title: '기타' },
  ],
  [
    { id: 0, title: '선택' },
    { id: 1, title: '지역관광' },
    { id: 2, title: '축제' },
    { id: 6, title: '기타' },
  ],
  [
    { id: 0, title: '선택' },
    { id: 1, title: '마케팅' },
    { id: 2, title: '캠페인' },
    { id: 6, title: '기타' },
  ],
];

const joinFormItems: any = [
  {
    title: '실명',
    key: 'pname',
    placeholder: '실명을 입력해 주세요.',
  },
  { title: '사번', key: 'pnumber', placeholder: '사번을 입력해 주세요.' },
  { title: '부서명', key: 'dname', placeholder: '부서명을 입력해 주세요.' },
  { title: '', key: 'direct', placeholder: '' },
];

interface Props {
  isSend: boolean;
  setIsSend: Dispatch<React.SetStateAction<boolean>>;
  communityData?: any;
  setCommunityData?: Dispatch<React.SetStateAction<any>> | undefined;
}

export const CommunityForm: React.FC<Props> = ({
  isSend = false,
  setIsSend,
  communityData = null,
  setCommunityData,
}) => {
  const location = useLocation();
  const MAX_GROUP_COUNT = 20;
  const dummyUserId = '00000000000000000000000000000003';
  const locationSearch = useRef<any>();
  const titleRef = useRef<any>();
  const groupInputRef = useRef<any>();
  const descriptionRef = useRef<any>();
  const companyNameRef = useRef<any>();
  const addTerm1Ref = useRef<any>();
  const addTerm2Ref = useRef<any>();
  const [chosenCommunity, setChosenCommunity] =
    useRecoilState(chosenCommunityState);
  const communitySearch = useRecoilValue(communitySearchState);
  const [page, setPage] = useState(1);
  const [resultLatLng, setResultLatLng] = useState<any>('');
  const [communityId, setCommunityId] = useState<string>('');
  const [official, setOfficial] = useState<any>(1);
  const [communityCoverImage, setCommunityCoverImage] = useState<string>('');
  const [communityCoverFile, setCommunityCoverFile] = useState<any>(null);
  const [findDataportal, setFindDataportal] = useState<string>('');
  const [connectDataportal, setConnectDataportal] = useState<any>();
  const [rangeDate, setRangeDate] = useState<any[]>([null, null]);
  const [communityGroup, setCommunityGroup] = useState<any>([]);
  const [region, setRegion] = useState<any>('');
  const [address, setAddress] = useState<any>([]);
  const [challengeHidden, setChallengeHidden] = useState<number>(0);
  const [usePost, setUsePost] = useState<number>(1);
  const [authRegionLimit, setAuthRegionLimit] = useState<any[]>([]);
  const [saveAuthRegionLimit, setSaveAuthRegionLimit] = useState<any[]>([]);
  const [authAddressLimit, setAuthAddressLimit] = useState<any[]>([]);
  const [saveAuthAddressLimit, setSaveAuthAddressLimit] = useState<any[]>([]);
  const [locationLimit, setLocationLimit] = useState<any[]>([]);
  const [saveLocationLimit, setSaveLocationLimit] = useState<any[]>([]);
  const [communityDivision, setCommunityDivision] = useState<any>([0, 0]);
  const [communityType, setCommunityType] = useState<any>(0);
  const [communityCreatorId, setCommunityCreatorId] = useState<string>('');
  const [communityExtraId, setCommunityExtraId] = useState<string>('');
  const [communityGeohash, setCommunityGeohash] = useState<string>('');
  const [communityOwnerName, setCommunityOwnerName] = useState<string>('');
  const [communityDefaultGroupId, setCommunityDefaultGroupId] =
    useState<string>(newUuidId());
  const [communityTitle, setCommunityTitle] = useState<string>('');
  const [communityTitleHelperText, setCommunityTitleHelperText] =
    useState<string>('');
  const [communityDescription, setCommunityDescription] = useState<string>('');
  const [communityDescriptionHelperText, setCommunityDescriptionHelperText] =
    useState<string>('');
  const [communityTerms, setCommunityTerms] = useState<string>('');
  const [communitySensitiveItems, setCommunitySensitiveItems] = useState(''); // 커뮤니티 제공항목(민감약관)
  const [communityTag, setCommunityTag] = useState<any>(['', '', '']);
  const [suggestion, setSuggestion] = useState<any>(0);
  const [memberLimit, setMemberLimit] = useState<any>(0);
  const [groupName, setGroupName] = useState<string>('');
  const [limitUser, setLimitUser] = useState<any>(0);
  const [joinCode, setJoinCode] = useState<string>('');
  const [uploadUrl, setUploadUrl] = useState<any>();
  const [imageUrlPrefix, setImageUrlPrefix] = useState<any>('');
  const [dialogTitle, setDialogTitle] = useState<string>('');
  const [dialogMessage, setDialogMessage] = useState<string>('');
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openMore, setOpenMore] = useState<number>(0);
  const [joinFormList, setJoinFormList] = useState<any[]>([]);
  const [openJoinForm, setOpenJoinForm] = useState<boolean>(false);
  const [checkedJoinFormItems, setCheckedJoinFormItems] = useState<string[]>(
    []
  );

  const { mutate: postCommunityMutate, isError: isMutateError } = useMutation(
    postCommunity,
    {
      onSuccess: res => {
        if (res.resultCode === RESULT_OK) {
          setOpenDialog(true);
          setDialogMessage('커뮤니티를 등록했습니다.');
        } else {
          setOpenDialog(true);
          setDialogMessage(
            `커뮤니티를 등록하는 동안 오류가 발생했습니다.(${res.resultCodeMsg})`
          );
        }
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '커뮤니티 저장이 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    }
  );

  const registerCommunity = (newUploadUrl: any) => {
    const isCreate = location.pathname.includes('create');
    const newCompanyName =
      companyNameRef.current?.value !== '' ? companyNameRef.current?.value : '';
    const newAddTerm1 =
      addTerm1Ref.current?.value !== '' ? addTerm1Ref.current?.value : '';
    const newAddTerm2 =
      addTerm2Ref.current?.value !== '' ? addTerm2Ref.current?.value : '';

    const newAgreement = {
      type: 1,
      title: '커뮤니티 개인정보 취급방침 동의',
      detail: `스왈라비는 원활한 서비스 이용을 위해 아래와 같이 사용자의 개인정보를 제 3자에게 제공하고 있습니다.
동의를 거부할 권리가 있으며, 동의를 거부하실 경우 커뮤니티 가입 및 이용이 제한됩니다.
1. 제공 받는 자 : 다른 회원(동일 커뮤니티 가입자)
① 제공 받는 자의 목적 : 걸음수 확인 및 비교, 커뮤니티 활동 참여
② 제공 항목 : 닉네임, 프로필 사진, 매일/주간/월간 걸음수, 신체활동 패턴
③ 보유 및 이용 기간 : 커뮤니티 탈퇴 시까지
2. 제공 받는 자 : ${newCompanyName}
① 제공 받는 자의 목적 : 커뮤니티 가입 회원의 건강 증진, 걷기 동기 부여 , 챌린지 안내, 커뮤니티 주관 행사 안내, 본인 인증, ${newAddTerm1} 등
② 제공 항목 : ${communitySensitiveItems}, 개인식별정보(실명, 부서명, 사번 등 커뮤니티 가입을 위한 추가정보 답변 입력시), 설문조사 답변, 관심있는 건강 정보, ${newAddTerm2}
③ 보유 및 이용 기간 : 커뮤니티 탈퇴시 혹은 계약 종료시까지
④ 제공 항목의 경우 변경될 수 있으며, 변경되는 경우 개별적으로 약관 변경에 대한 안내를 드립니다.
* 단, 차단된 사용자의 경우 커뮤니티를 탈퇴하더라도 계약 종료시까지 닉네임 및 휴대전화번호를 제공함.`,
      option: communityTerms,
    };
    const newLtLng = resultLatLng.split(' ');
    console.log('newLt', newLtLng);
    console.log('address:', address);
    const newGeohash = componentGeohash.encode(
      newLtLng[0] ? Number(newLtLng[0]) : 0,
      newLtLng[1] ? Number(newLtLng[1]) : 0,
      4
    );
    const newImage =
      communityCoverFile !== null
        ? newUploadUrl.file.path
        : communityCoverImage.length > 0
        ? communityCoverImage
        : '';
    const newCommunityGroup = communityGroup.map((item: any, idx: number) => {
      const newData = {
        groupId: item.groupId,
        name: item.name,
        isDefault: item.isDefault,
        groupOrder: idx + 1,
      };
      return newData;
    });
    const newAuthRegion = saveAuthRegionLimit.map((item: any) => {
      const newData = {
        targetType: 1,
        targetAddress: item.location,
        targetAmd: item.amd,
      };
      return newData;
    });
    const newAuthAddress = saveAuthAddressLimit.map((item: any) => {
      const newData = {
        targetType: 1,
        targetAddress: item.location,
        targetAmd: item.amd,
      };
      return newData;
    });
    const newJoinForm =
      joinFormList !== null && joinFormList.length > 0
        ? joinFormList.map((item: any, idx: number) => {
            const newItem: any = {
              idx: idx + 1,
              fixed: item.key !== 'direct' ? 1 : 0,
              title: trimWhiteSpace(item.title),
              holder: trimWhiteSpace(item.placeholder),
            };
            return newItem;
          })
        : [];

    const nowDate = new Date(Date.now());
    const newCommunityData: ICommunity = {
      official: official,
      communityId: communityId,
      challengeHidden: communityDivision[0] !== 2 ? challengeHidden : 1,
      contractTypeMaster: communityDivision[0],
      contractTypeSub: communityDivision[1],
      memberLimit:
        limitUser === -1 ? memberLimit : limitUser === 0 ? 100 : limitUser,
      creatorId: isCreate
        ? dummyUserId
        : communityCreatorId.length > 0
        ? communityCreatorId
        : dummyUserId,
      type: communityType,
      name: trimWhiteSpace(communityTitle),
      desc: trimWhiteSpace(communityDescription),
      image: newImage,
      tags: communityTag.filter((tag: string) => tag !== ''),
      groups: newCommunityGroup,
      startDate:
        rangeDate[0] === null
          ? nowDate.getTime() / 1000
          : rangeDate[0].getTime() / 1000,
      endDate:
        rangeDate[1] === null
          ? new Date(`2037-12-31T23:59:59`).getTime() / 1000
          : rangeDate[1].getTime() / 1000,
      agreement: JSON.stringify(newAgreement),
      additionalAgreement: communityTerms,
      la: newLtLng[0] ? newLtLng[0] : 0.0,
      lo: newLtLng[1] ? newLtLng[1] : 0.0,
      address: address.length > 0 ? `${address[0]} ${address[1]}` : '',
      extraId: communityExtraId !== null ? communityExtraId : '',
      geohash: newGeohash,
      ownerName:
        connectDataportal !== undefined &&
        connectDataportal !== null &&
        connectDataportal.portalId !== undefined &&
        connectDataportal.portalId !== null
          ? connectDataportal?.name
          : '워크온',
      defaultGroupId:
        communityDefaultGroupId !== null
          ? communityDefaultGroupId
          : newUuidId(),
      portalId:
        connectDataportal !== undefined &&
        connectDataportal !== null &&
        connectDataportal.portalId !== undefined &&
        connectDataportal.portalId !== null
          ? connectDataportal?.portalId
          : 'ADMIN',
      authRegions: newAuthRegion,
      authAddress: newAuthAddress,
      registerStatus: 3,
      isSearch: suggestion,
      joinForm: JSON.stringify(newJoinForm),
      joinCode: joinCode,
      usePost: usePost,
      hiddenStep: isCreate ? 0 : communityData.hiddenStep,
      useRank: isCreate ? 1 : communityData.useRank,
    };

    var isValid = 0;
    if (isValid === 0 && newCommunityData.name.length === 0) {
      isValid = 1;
    }
    if (isValid === 0 && newCommunityData.desc.length === 0) {
      isValid = 2;
    }
    if (
      isValid === 0 &&
      official === 1 &&
      (newCommunityData.ownerName === null ||
        newCommunityData.ownerName.length === 0 ||
        newCommunityData.portalId === null ||
        newCommunityData.portalId.length === 0)
    ) {
      isValid = 3;
    }
    // if (
    //   isValid === 0 &&
    //   official === 1 &&
    //   (newCommunityData.startDate === 0 || newCommunityData.endDate === 0)
    // ) {
    //   isValid = 4;
    // }
    if (isValid === 0 && official === 1 && newCommunityData.tags.length === 0) {
      isValid = 5;
    }
    if (
      isValid === 0 &&
      official === 1 &&
      (companyNameRef.current?.value === null ||
        companyNameRef.current?.value === '')
    ) {
      isValid = 6;
    }
    if (
      isValid === 0 &&
      official === 1 &&
      (newCommunityData.image === null || newCommunityData.image.length === 0)
    ) {
      isValid = 7;
    }
    if (
      isValid === 0 &&
      communityType === 2 &&
      (joinCode.length < 4 || joinCode.length > 12)
    ) {
      isValid = 8;
    }
    if (isValid === 0) {
      setChosenCommunity(newCommunityData);
      postCommunityMutate(newCommunityData);
    } else {
      var message = '';
      switch (isValid) {
        case 1:
          message = '커뮤니티 이름을 입력해 주세요';
          break;
        case 2:
          message = '커뮤니티 소개를 입력해 주세요.';
          break;
        case 3:
          message = '데이터 포탈 아이디를 연결해주세요.';
          break;
        case 4:
          message = '커뮤니티 운영기간을 선택해주세요.';
          break;
        case 5:
          message = '커뮤니티 태그를 1개 이상 선택해주세요.';
          break;
        case 6:
          message = '약관 기관명을 입력해주세요.';
          break;
        case 7:
          message = '커버 이미지를 등록해 주세요.';
          break;
        case 8:
          message = '가입코드 길이를 확인해주세요 (4~12자).';
          break;
        default:
          message = '다시 입력해 주세요.';
      }
      setOpenDialog(true);
      setDialogMessage(message);
    }
  };

  const { mutate: uploadFileMutate, isError: isUploadMutateError } =
    useMutation(postUploadFile, {
      onSuccess: res => {
        console.log('uploadFile:', res);
        setUploadUrl(res.uploadUrl.file.path);
        console.log('uploadFile uploadUrl:', uploadUrl);
        registerCommunity(res.uploadUrl);
      },
      onError: error => {
        setOpenDialog(true);
        setDialogMessage(
          '파일 업로드시 실패했습니다. 다시 시도해주시기 바랍니다.'
        );
      },
    });

  const checkCommunity = () => {
    setOpenMore(0);
    if (communityCoverFile === null) {
      registerCommunity(null);
    } else {
      const uploadfile: IUploadFile = {
        type: 'community',
        file: communityCoverFile,
      };
      uploadFileMutate(uploadfile);
    }
  };

  const onChangeGroup = () => {
    const newGroup = [groupName];
    const setNewGroup = newGroup.map((group: any) => {
      const newId = newUuidId();
      return {
        communityId: communityId,
        isDefault: 0,
        deleted: 0,
        groupId: newId,
        name: group,
      };
    });
    if (newGroup) {
      const newGroups = [...communityGroup, ...setNewGroup];
      // const filteredGroup = newGroups.filter(
      //   (q: any, idx: number) => idx < MAX_GROUP_COUNT
      // );
      setCommunityGroup(newGroups);
    }
  };

  const addGroupBatch = (groupArray: any) => {
    const setNewGroup = groupArray.map((group: any) => {
      const newId = newUuidId();
      return {
        communityId: communityId,
        isDefault: 0,
        deleted: 0,
        groupId: newId,
        name: group[0],
      };
    });
    if (groupArray) {
      const newGroups = [...communityGroup, ...setNewGroup];
      // const filteredGroup = newGroups.filter(
      //   (q: any, idx: number) => idx < MAX_GROUP_COUNT
      // );
      setCommunityGroup(newGroups);
    }
  };

  const deleteSomething = (index: number, from: string) => {
    const newSomething = (
      from === 'group'
        ? communityGroup
        : from === 'tag'
        ? communityTag
        : locationLimit
    ).filter((_val: any, i: number) => i !== index);
    from === 'group'
      ? setCommunityGroup([...newSomething])
      : from === 'tag'
      ? setCommunityTag([...newSomething])
      : setLocationLimit([...newSomething]);
  };

  const addCommunityTags = () => {
    if (communityTag?.length > 7) {
      setDialogTitle('커뮤니티 태그');
      setDialogMessage('커뮤니티 태그는 8개까지 생성가능합니다.');
      setOpenDialog(true);
      return;
    }
    setCommunityTag([...communityTag, '']);
  };

  const onChangeTags = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newTag = evt.target.value;
    const tags = [...communityTag];
    tags[index] = newTag;
    setCommunityTag(tags);
  };

  const geocoder = new window.kakao.maps.services.Geocoder();
  const searchingLatLng = () => {
    geocoder.addressSearch(
      locationSearch.current?.value,
      (result: any, status: any) => {
        const wanted = new window.kakao.maps.LatLng(result[0].y, result[0].x);
        console.log('coords>>>>>>>>>>>>', wanted);
        setResultLatLng(wanted);
      }
    );
  };

  function makeCols(refstr: any) {
    const o = [];
    const C = XLSX.utils.decode_range(refstr).e.c + 1;
    for (var i = 0; i < C; i += 1)
      o[i] = { name: XLSX.utils.encode_col(i), key: i };
    return o;
  }

  const parsingExcel = (content: any, opt: any) => {
    const rABS = !!opt;
    const wb = XLSX.read(content, { type: rABS ? 'binary' : 'array' });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];

    console.log(rABS, wb);
    /* Convert array of arrays */
    const data = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' });

    console.log('data: ', data);
    console.log('cols:', makeCols(ws['!ref']));
  };

  const uploadExcelFile = (evt: ChangeEvent<HTMLInputElement>) => {
    const excelFile = evt.target.files && evt.target.files[0];

    var formData = new FormData();
    if (excelFile !== null) formData.append('file', excelFile);

    xapi
      .post(`/admin/v2/apis/upload/community/add-group`, formData)
      .then(res => {
        groupInputRef.current.value = '';
        if (res.status !== 200) throw new Error('http 에러');
        setOpenDialog(true);
        setDialogMessage('엑셀 업로드로 커뮤니티 그룹을 등록했습니다.');
        addGroupBatch(res.data.group);
        return res.data;
      });
  };

  const isSending = (type: any) => {
    if (type === 0) {
      setIsSend(false);
      setDialogMessage(`${communityTitle} 커뮤니티를 개설하시겠습니까?`);
      setOpenMore(1);
    } else {
      setIsSend(false);
      setDialogMessage(`변경 사항을 저장하시겠습니까?`);
      setOpenMore(1);
    }
  };

  const deleteAuthRegion = (index: number, from: string) => {
    const deleted = saveAuthRegionLimit[index].location;
    const newLocation = saveAuthRegionLimit.filter(
      (_val: any, i: number) => _val.location !== deleted
    );
    if (newLocation.length !== saveAuthRegionLimit.length)
      setSaveAuthRegionLimit([...newLocation]);
  };

  const deleteAllAuthRegion = () => {
    setSaveAuthRegionLimit([]);
  };

  const deleteAuthAddress = (index: number, from: string) => {
    const deleted = saveAuthAddressLimit[index].location;
    const newLocation = saveAuthAddressLimit.filter(
      (_val: any, i: number) => _val.location !== deleted
    );
    if (newLocation.length !== saveAuthAddressLimit.length)
      setSaveAuthAddressLimit([...newLocation]);
  };

  const deleteAllAuthAddress = () => {
    setSaveAuthAddressLimit([]);
  };

  const deleteJoinFormList = (idx: number) => {
    const newItems = checkedJoinFormItems.filter(
      (item: any, i: number) => i !== idx
    );
    setCheckedJoinFormItems(newItems);
    const newJoinFormList = joinFormList.filter(
      (item: any, i: number) => i !== idx
    );
    setJoinFormList(newJoinFormList);
  };

  const addJoinFormList = (id: string) => {
    const newItemData = joinFormItems.filter((item: any) => item.key === id);

    if (newItemData === null || newItemData.length === 0) {
      setDialogMessage(`추가 정보 처리중 오류가 발생했습니다. : ${id}`);
      setOpenSnackbar(true);
      return;
    }

    const newItems = [...checkedJoinFormItems, id];
    setCheckedJoinFormItems(newItems);

    const newJoinFormList = [...joinFormList, newItemData[0]];
    setJoinFormList(newJoinFormList);
  };

  const changeJoinFormItemsHandler = (checked: boolean, id: string) => {
    if (checked) {
      if (joinFormList.length >= MAX_JOIN_FORM_SIZE) {
        setDialogMessage('추가 정보는 최대 5개까지 선택 가능합니다.');
        setOpenSnackbar(true);
        return;
      }
      addJoinFormList(id);
    } else {
      const index = checkedJoinFormItems.findIndex(button => button === id);
      deleteJoinFormList(index);
    }
  };

  const addJoinFormDirectItemsHandler = () => {
    if (joinFormList.length >= MAX_JOIN_FORM_SIZE) {
      setDialogMessage('추가 정보는 최대 5개까지 선택 가능합니다.');
      setOpenSnackbar(true);
      return;
    }
    const newItems = [...checkedJoinFormItems, 'direct'];
    setCheckedJoinFormItems(newItems);
    const newJoinFormList = [
      ...joinFormList,
      { key: 'direct', title: '', placeholder: '' },
    ];
    setJoinFormList(newJoinFormList);
  };

  const changeJoinForm = (idx: number, type: string, msg: string) => {
    const newJoinFormList = joinFormList.map((item: any, i: number) => {
      let newItem = item;
      if (idx === i) {
        if (type === 'placeholder') {
          newItem = { ...item, placeholder: msg };
        } else if (type === 'title') {
          newItem = { ...item, title: msg };
        } else {
          newItem = { ...item };
        }
      }
      return newItem;
    });
    setJoinFormList(newJoinFormList);
  };

  useMemo(() => {
    if (communityTitle?.length > 50) {
      setCommunityTitleHelperText('50자 이내로 작성해주세요!');
    } else if (communityTitleHelperText !== '') {
      setCommunityTitleHelperText('');
    }
  }, [communityTitle]);
  useMemo(() => {
    if (communityDescription?.length > 1000) {
      setCommunityDescriptionHelperText('1000자 이내로 작성해주세요!');
    } else if (communityDescriptionHelperText !== '') {
      setCommunityDescriptionHelperText('');
    }
  }, [communityDescription]);

  // useEffect(() => {
  //   console.log('region : ', region);
  // }, [region]);

  // useEffect(() => {
  //   console.log('address : ', address);
  // }, [address]);

  useEffect(() => {
    setSaveLocationLimit([...saveLocationLimit, ...locationLimit]);
    console.log('locationLimit :', locationLimit);
  }, [locationLimit]);

  useEffect(() => {
    setSaveAuthRegionLimit([...saveAuthRegionLimit, ...authRegionLimit]);
  }, [authRegionLimit]);

  useEffect(() => {
    setSaveAuthAddressLimit([...saveAuthAddressLimit, ...authAddressLimit]);
  }, [authAddressLimit]);

  useEffect(() => {
    if (isSend === false && communityData?.communityId) {
      console.log('communityData :', communityData);
      setCommunityId(communityData?.communityId);
      setChallengeHidden(communityData?.challengeHidden || 0);
      setUsePost(communityData?.usePost);
      setOfficial(communityData?.official);
      setCommunityDivision([
        communityData?.contractTypeMaster !== -1
          ? communityData?.contractTypeMaster
          : 0,
        communityData?.contractTypeSub !== -1
          ? communityData?.contractTypeSub
          : 0,
      ]);
      setMemberLimit(communityData?.memberLimit);
      const newLimitUser =
        communityData?.memberLimit === 100
          ? 100
          : communityData?.memberLimit === 300
          ? 300
          : communityData?.memberLimit === 500
          ? 500
          : -1;
      setLimitUser(newLimitUser);
      setCommunityCreatorId(
        communityData?.creatorId !== '' ? communityData?.creatorId : dummyUserId
      );
      setCommunityExtraId(communityData?.extraId);
      setCommunityType(communityData?.type);
      setJoinCode(communityData?.joinCode);
      setCommunityTitle(communityData?.name);
      setCommunityOwnerName(communityData?.ownerName);
      setCommunityDefaultGroupId(communityData?.defaultGroupId);
      setCommunityDescription(communityData?.desc);
      setImageUrlPrefix(communityData?.imageUrlPrefix);
      setCommunityCoverImage(communityData?.image);
      setCommunityGeohash(communityData?.geohash);
      setResultLatLng(`${communityData?.la} ${communityData?.lo}`);
      if (
        communityData?.location !== undefined &&
        communityData?.location !== null &&
        communityData?.location.length > 0
      ) {
        const newData = communityData.location.split(' ');
        setAddress([newData[0], newData[1]]);
        setRegion(communityData.location);
      }
      communityData?.agreement?.option &&
        setCommunityTerms(communityData?.agreement?.option);
      setCommunitySensitiveItems(
        communityData?.sensitiveItemList
          ?.filter((list: any) => list?.isCollected)
          ?.map((item: any) => item.name)
          ?.join(', ')
      );
      setCommunityTag(
        communityData?.tags !== null ? communityData?.tags : ['']
      );
      const newGroup = communityData?.groups.map((group: any) => {
        const tmpGroup = {
          groupId: group.groupId,
          name: group.name,
          isDefault: group.isDefault,
        };
        return tmpGroup;
      });
      setCommunityGroup(newGroup);
      setConnectDataportal({
        portalId:
          communityData.dataPortalId !== null ? communityData.dataPortalId : '',
        name: communityData.ownerName,
      });

      const newRangeDate = [...rangeDate];
      const nowDate = new Date(Date.now());
      const nowYear = nowDate.getFullYear();
      newRangeDate[0] = new Date(communityData?.timestamp);
      newRangeDate[1] =
        communityData?.endTimestamp !== null
          ? new Date(communityData?.endTimestamp)
          : new Date(`${nowYear}-12-31T23:59:59`);
      setRangeDate(newRangeDate);

      const newAuthRegionLimit = JSON.parse(communityData?.authRegions);
      const newAuthRegion =
        newAuthRegionLimit &&
        newAuthRegionLimit.map((item: any) => {
          const newData = {
            location: item.TARGET_ADDRESS,
            amd: item.TARGET_AMD,
          };
          return newData;
        });
      setSaveAuthRegionLimit(newAuthRegion);

      const newAuthAddressLimit =
        communityData?.authAddress !== undefined &&
        communityData?.authAddress !== null
          ? JSON.parse(communityData?.authAddress)
          : [];
      const newAuthAddress =
        newAuthAddressLimit &&
        newAuthAddressLimit.map((item: any) => {
          const newData = {
            location: item.TARGET_ADDRESS,
            amd: item.TARGET_AMD,
          };
          return newData;
        });
      setSaveAuthAddressLimit(newAuthAddress);

      setSuggestion(communityData?.isSearch);

      const newAgreement =
        communityData?.agreement === null
          ? ''
          : communityData?.agreement.detail === null
          ? ''
          : communityData?.agreement.detail;
      const startName = newAgreement.indexOf('2. 제공 받는 자 : ');
      const endName = newAgreement.indexOf('① 제공 받는 자의 목적 : 커뮤니티');
      const startPurpose = newAgreement.indexOf(
        '커뮤니티 주관 행사 안내, 본인 인증,'
      );
      const tmpPos = newAgreement.indexOf('② 제공 항목 : 닉네임, 휴대전화번호');
      const endPurpose =
        tmpPos !== -1
          ? tmpPos
          : newAgreement.indexOf('② 제공 항목 : 닉네임, 연락처(휴대폰 번호)');
      const startContent = newAgreement.indexOf(
        '설문조사 답변, 관심있는 건강 정보,'
      );
      const endContent = newAgreement.indexOf(
        '③ 보유 및 이용 기간 : 커뮤니티 탈퇴시 혹은 계약 종료시까지'
      );
      setCommunityTerms(communityData?.additionalAgreement);

      var newCompany = '';
      var newPurpose = '';
      var newContent = '';
      if (startName !== -1 && startPurpose !== -1 && startContent !== -1) {
        newCompany = newAgreement.slice(startName + 12, endName).trim();
        const tmpPurpose = newAgreement
          .slice(startPurpose + 21, endPurpose)
          .trim();
        newPurpose = tmpPurpose.slice(0, tmpPurpose.length - 1).trim();
        newContent = newAgreement.slice(startContent + 20, endContent).trim();
      }
      companyNameRef.current.value = newCompany;
      addTerm1Ref.current.value = newPurpose;
      addTerm2Ref.current.value = newContent;

      const newParsedJoinForm =
        communityData?.joinForm !== null &&
        communityData?.joinForm !== undefined
          ? JSON.parse(communityData?.joinForm)
          : [];
      if (newParsedJoinForm.length > 0) {
        const newFormItems: any[] = [];
        const newFormList: any[] = [];
        newParsedJoinForm.map((item: any, idx: number) => {
          if (item.fixed === 1) {
            const newItem = joinFormItems.filter(
              (it: any) => it.title === item.title
            );
            const newKey = newItem !== null ? newItem.key : 'direct';
            newFormItems.push(newKey);
            newFormList.push({
              title: item.title,
              key: newKey,
              placeholder: item.holder,
            });
          } else {
            newFormItems.push('direct');
            newFormList.push({
              title: item.title,
              key: 'direct',
              placeholder: item.holder,
            });
          }
        });
        setCheckedJoinFormItems(newFormItems);
        setJoinFormList(newFormList);
      } else {
        setJoinFormList([]);
        setCheckedJoinFormItems([]);
      }
    } else if (isSend === false && communityData === null) {
      setCommunityExtraId('');
      console.log('communityId is null');
    }
  }, [communityData]);

  return (
    <>
      <CreatePaper>
        <Stack
          direction={{ xs: 'column', sm: 'column', md: 'row' }}
          divider={
            <Divider
              orientation='vertical'
              flexItem
              sx={{ p: 0, m: '1.5rem 3rem 0' }}
            />
          }
        >
          <Box>
            <Typography className='title'>사용한 이용권</Typography>
            <TextField
              sx={{ width: '18rem' }}
              disabled
              defaultValue='공식 커뮤니티 개설 이용권'
            />
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>챌린지 노출 여부</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_COMMUNITY_OPEN}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              select
              sx={{ width: '10rem' }}
              value={challengeHidden}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setChallengeHidden(Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={0}>
                노출함
              </MenuItem>
              <MenuItem key={1} value={1}>
                노출 안함
              </MenuItem>
            </TextField>
          </Box>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>
                커뮤니티 게시판 (사용안함)
              </Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_COMMUNITY_POST_USE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              select
              sx={{ width: '10rem' }}
              value={usePost}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => {
                setUsePost(Number(event.target.value));
              }}
            >
              <MenuItem key={0} value={0}>
                사용안함
              </MenuItem>
              <MenuItem key={1} value={1}>
                사용
              </MenuItem>
            </TextField>
          </Box>
        </Stack>
        <Divider />
        <Box>
          <Typography className='title'>커뮤니티 이름</Typography>
          <TextField
            fullWidth
            placeholder='커뮤니티 이름을 50자 이내로 작성해주세요.'
            helperText={communityTitleHelperText}
            inputRef={titleRef}
            onChange={evt => {
              setCommunityTitle(evt.target.value.substring(0, 50));
            }}
            value={communityTitle}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{communityTitle?.length} / 50</span>
                </InputAdornment>
              ),
            }}
          />
          <Divider />
        </Box>
        <Box>
          <SwUuidCreate
            title='커뮤니티'
            id={communityId}
            setId={setCommunityId}
          />
          <Divider />
        </Box>
        {/* <Box>
          <Typography className='title'>커뮤니티 운영기간</Typography>
          <SwDateRangePicker
            rangeDate={rangeDate}
            setRangeDate={setRangeDate}
          />
          <Divider />
        </Box> */}
        <Box>
          <DataportalConnect
            findDataportal={findDataportal}
            setFindDataportal={setFindDataportal}
            connectDataportal={connectDataportal}
            setConnectDataportal={setConnectDataportal}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>커뮤니티 인원 제한</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_COMMUNITY_MEMBER_LIMIT}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <TextField
            sx={{ width: '16rem', mr: '1rem' }}
            select
            defaultValue={0}
            value={limitUser}
            onChange={evt => setLimitUser(evt.target.value)}
          >
            <MenuItem value={0}>인원수 선택</MenuItem>
            <MenuItem value={100}>100명</MenuItem>
            <MenuItem value={300}>300명</MenuItem>
            <MenuItem value={500}>500명</MenuItem>
            <MenuItem value={-1}>직접입력</MenuItem>
          </TextField>
          {limitUser === -1 && (
            <TextField
              placeholder='인원수 입력'
              value={memberLimit}
              onChange={evt => setMemberLimit(evt.target.value)}
            />
          )}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>커뮤니티 형태</Typography>
          <TextField
            sx={{ width: '10rem', mr: '1em' }}
            select
            defaultValue={0}
            value={communityDivision[0]}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => setCommunityDivision([event.target.value, '0'])}
          >
            {types.map(option => (
              <MenuItem key={`division_${option.id}`} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            sx={{ width: '10rem' }}
            select
            defaultValue={0}
            value={communityDivision[1]}
            onChange={(
              event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) =>
              setCommunityDivision([communityDivision[0], event.target.value])
            }
          >
            {typeSubs[communityDivision[0]].map(option => (
              <MenuItem key={`division2_${option.id}`} value={option.id}>
                {option.title}
              </MenuItem>
            ))}
          </TextField>
          <Divider />
        </Box>
        <Box className='flex_start'>
          <Box>
            <Box className='flex_start'>
              <Typography className='title'>커뮤니티 종류</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_COMMUNITY_TYPE}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <TextField
              sx={{ width: '12rem' }}
              select
              defaultValue='0'
              value={communityType}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
              ) => setCommunityType(Number(event.target.value))}
            >
              <MenuItem value='0'>공개형</MenuItem>
              <MenuItem value='1'>승인가입형</MenuItem>
              <MenuItem value='2'>코드 입력형</MenuItem>
            </TextField>
          </Box>
          {communityType === 2 && (
            <Box className='flex_start' sx={{ ml: '1rem', mt: '2.5rem' }}>
              <Box>
                <TextField
                  placeholder='가입 코드를 입력해주세요'
                  onChange={evt => {
                    setJoinCode(evt.target.value.substring(0, 12));
                  }}
                  value={joinCode}
                />
              </Box>
              {!checkJoinCode(joinCode) && (
                <Box>
                  <Typography sx={{ ml: '1rem', color: 'red' }}>
                    영문(대소문자 구분) 및 숫자 4~12자로 설정해주세요
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Divider />
        <Box>
          <Typography className='title'>커뮤니티 소개</Typography>
          <TextField
            fullWidth
            multiline
            rows={6}
            onChange={evt =>
              setCommunityDescription(evt.target.value.substring(0, 1000))
            }
            value={communityDescription}
            placeholder='커뮤니티 소개글을 1000자 이내로 작성해주세요.'
            helperText={communityDescriptionHelperText}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <span>{communityDescription?.length} / 1000</span>
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': { height: 'auto', padding: '.5rem' },
            }}
          />
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>커버 이미지</Typography>
          <SwFileSetting
            setImage={setCommunityCoverImage}
            image={
              communityCoverFile !== null
                ? communityCoverImage
                : communityCoverImage !== ''
                ? `${imageUrlPrefix}${communityCoverImage}`
                : ''
            }
            setFile={setCommunityCoverFile}
            file={communityCoverFile}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>추천 커뮤니티 노출 여부</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_COMMUNITY_RECOMMEND}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <TextField
            sx={{ width: '12rem' }}
            select
            defaultValue={0}
            value={suggestion}
            onChange={evt => setSuggestion(evt.target.value)}
          >
            <MenuItem value={0}>아니오</MenuItem>
            <MenuItem value={1}>예</MenuItem>
          </TextField>
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>위치 정보</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_COMMUNITY_LOCATION}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <SwFindLatLngbyKakaomap
            setLatLng={setResultLatLng}
            value={resultLatLng}
            location={region}
            setLocation={setRegion}
            setAddress={setAddress}
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start'>
            <Typography className='title'>가입 추가 정보 (선택)</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_COMMUNITY_JOIN_INFO}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <Typography className='' sx={{ mb: '.5rem' }}>
            커뮤니티 가입시 멤버들에게 입력받고 싶은 정보가 있으면 추가해
            주세요. ex) 사번, 부서명 등등
          </Typography>
          {joinFormList.map((form: any, idx: number) => (
            <>
              <JoinFormPaper sx={{ width: '50%' }}>
                <Box className='flex_between'>
                  <Typography className='title'>추가정보</Typography>
                  <IconButton
                    size='small'
                    onClick={() => deleteJoinFormList(idx)}
                  >
                    <ClearIcon sx={{ fontSize: '1.5rem' }} />
                  </IconButton>
                </Box>
                <Divider sx={{ p: 0, m: 0 }} />
                {form.key !== 'direct' ? (
                  <Typography className='title'>{form.title}</Typography>
                ) : (
                  <TextField
                    fullWidth
                    placeholder='정보명을 입력해주세요.'
                    value={form.title}
                    onChange={(
                      event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                    ) => changeJoinForm(idx, 'title', event.target.value)}
                  />
                )}
                <TextField
                  fullWidth
                  placeholder={form.placeholder}
                  value={form.placeholder}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => changeJoinForm(idx, 'placeholder', event.target.value)}
                />
              </JoinFormPaper>
            </>
          ))}
          <Box className='flex_center'>
            <Button
              sx={{ width: '50%' }}
              variant='outlined'
              color='info'
              onClick={() => setOpenJoinForm(!openJoinForm)}
            >
              정보 추가하기
              <ArrowDropDownIcon />
            </Button>
          </Box>
          <Box>
            {openJoinForm && (
              <Paper sx={{ width: '50%' }}>
                {joinFormItems
                  .filter((item: any) => item.key !== 'direct')
                  .map((item: any, idx: number) => (
                    <Box key={`join_form_${idx}`} className='flex_start'>
                      <Checkbox
                        onChange={e => {
                          changeJoinFormItemsHandler(
                            e.currentTarget.checked,
                            item.key
                          );
                        }}
                        checked={checkedJoinFormItems.includes(item.key)}
                      />
                      <Typography className=''>{item.title}</Typography>
                    </Box>
                  ))}
                <Divider sx={{ p: 0, m: 0 }} />
                <Box className='flex_between'>
                  <Button
                    color='info'
                    onClick={() => addJoinFormDirectItemsHandler()}
                  >
                    직접입력
                  </Button>
                  <AddIcon color='primary' sx={{ mr: '1rem' }} />
                </Box>
              </Paper>
            )}
          </Box>
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>커뮤니티 약관</Typography>
          <TermPaper>커뮤니티 개인정보 취급방침 동의</TermPaper>
          <TermPaper>
            <Typography component='div'>
              스왈라비는 원활한 서비스 이용을 위해 아래와 같이 사용자의
              개인정보를 제 3자에게 제공하고 있습니다.
            </Typography>
            <Typography component='div'>
              동의를 거부할 권리가 있으며, 동의를 거부하실 경우 커뮤니티 가입 및
              이용이 제한됩니다.
            </Typography>
            <Typography component='div'>
              {' '}
              1. 제공 받는 자 : 다른 회원(동일 커뮤니티 가입자)
            </Typography>
            <Typography component='div'>
              ① 제공 받는 자의 목적 : 걸음수 확인 및 비교, 커뮤니티 활동 참여
            </Typography>
            <Typography component='div'>
              ② 제공 항목 : 닉네임, 프로필 사진, 매일/주간/월간 걸음수, 신체활동
              패턴
            </Typography>
            <Typography component='div'>
              ③ 보유 및 이용 기간 : 커뮤니티 탈퇴 시까지
            </Typography>
            <Box className='flex_start'>
              <Typography component='div'> 2. 제공 받는 자 :</Typography>
              <TextField
                sx={{ ml: '.5rem', width: '20rem' }}
                inputRef={companyNameRef}
                placeholder='(필수)기관명을 입력해 주세요'
              />
            </Box>
            <Box className='flex_start'>
              <Typography component='div'>
                ① 제공 받는 자의 목적 : 커뮤니티 가입 회원의 건강 증진, 걷기
                동기 부여 , 챌린지 안내, 커뮤니티 주관 행사 안내, 본인 인증,
              </Typography>
              <TextField
                sx={{ ml: '.5rem', width: '20rem' }}
                inputRef={addTerm1Ref}
                placeholder='(선택)추가사항이 있다면 입력해 주세요'
              />
              <Typography component='div'> 등</Typography>
            </Box>
            <Box className='flex_start'>
              <Typography component='div'>
                ② 제공 항목 : {communitySensitiveItems}, 개인식별정보(실명,
                부서명, 사번 등 커뮤니티 가입을 위한 추가정보 답변 입력시),
                설문조사 답변, 관심있는 건강 정보,
              </Typography>
              <TextField
                sx={{ ml: '.5rem', width: '20rem' }}
                inputRef={addTerm2Ref}
                placeholder='(선택)추가사항이 있다면 입력해 주세요'
              />
            </Box>
            <Typography component='div'>
              ③ 보유 및 이용 기간 : 커뮤니티 탈퇴시 혹은 계약 종료시까지
            </Typography>
            <Typography component='div'>
              ④ 제공 항목의 경우 변경될 수 있으며, 변경되는 경우 개별적으로 약관
              변경에 대한 안내를 드립니다.
            </Typography>
            <Typography component='div'>
              * 단, 차단된 사용자의 경우 커뮤니티를 탈퇴하더라도 계약 종료시까지
              닉네임 및 휴대전화번호를 제공함.
            </Typography>
          </TermPaper>

          <AddGroupTextField
            fullWidth
            multiline
            rows={4}
            value={communityTerms}
            onChange={evt => setCommunityTerms(evt.target.value)}
            placeholder='(선택)추가 약관을 입력해 주세요. '
          />
          <Divider />
        </Box>
        <Box>
          <Box className='flex_center'>
            <Box className='flex_start'>
              <Typography className='title'>커뮤니티 태그</Typography>
              <Tooltip
                title={
                  <Typography fontSize={TOOLTIP_FONT_SIZE}>
                    {TOOLTIP_COMMUNITY_TAG}
                  </Typography>
                }
                followCursor
              >
                <HelpOutlineIcon sx={{ color: '#999999' }} />
              </Tooltip>
            </Box>
            <IconButton
              sx={{ mb: '.33rem' }}
              size='small'
              onClick={addCommunityTags}
            >
              <AddOutlinedIcon sx={{ fontSize: '1.2rem' }} />
            </IconButton>
          </Box>
          {communityTag?.map((tag: string, idx: number) => (
            <TextField
              key={idx.toString()}
              sx={{ mr: '1rem' }}
              placeholder='태그 입력'
              value={tag}
              onChange={evt => onChangeTags(evt, idx)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      size='small'
                      onClick={() => deleteSomething(idx, 'tag')}
                    >
                      <ClearIcon sx={{ fontSize: '1rem' }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          ))}
          <Divider />
        </Box>
        <Box>
          <Typography className='title'>그룹</Typography>
          {location.pathname.includes('create') && (
            <Box className='flex_between'>
              <Box>
                <AddGroupTextField
                  onChange={evt =>
                    setGroupName(evt.target.value.substring(0, 20))
                  }
                  value={groupName}
                  sx={{ width: '24rem' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <span>{groupName.length} / 20</span>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button variant='contained' onClick={onChangeGroup}>
                  추가
                </Button>
              </Box>
              <Box sx={{ mr: '2rem' }}>
                <Button
                  variant='contained'
                  color='info'
                  onClick={() => {
                    const gpxfile = document.getElementById(
                      'community_group_excel'
                    );
                    gpxfile?.click();
                  }}
                >
                  엑셀 업로드
                </Button>
                <input
                  className='nonedisplay'
                  id='community_group_excel'
                  type='file'
                  ref={groupInputRef}
                  onChange={uploadExcelFile}
                />
              </Box>
            </Box>
          )}
          <Paper className='chip_container'>
            <Typography>그룹 개수 : {`(${communityGroup?.length})`}</Typography>
            {communityGroup?.length > 0 &&
              communityGroup.map((group: any, idx: number) => (
                <NoneBorderChip
                  key={idx.toString()}
                  label={group?.name}
                  variant='outlined'
                  onDelete={() => deleteSomething(idx, 'group')}
                />
              ))}
            {location.pathname.includes('create') && (
              <div className='flex_end'>
                <Button
                  color='info'
                  onClick={() => setCommunityGroup([])}
                  sx={{ fontSize: '.8rem' }}
                >
                  <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
                  전체삭제
                </Button>
              </div>
            )}
          </Paper>
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start' sx={{ mb: '1rem' }}>
            <Typography sx={{ mr: '.4rem' }}>
              동네(프로필 인증 지역) 제한
            </Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_COMMUNITY_REGION_AUTH}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <Box>
            <SwSelectLocation
              limitKu={true}
              includeAmd={true}
              selectOne={true}
              showButton={false}
              showDepth1={false}
              location={authRegionLimit}
              setLocation={setAuthRegionLimit}
              showContainer={false}
              mapId='authRegion_map'
            />
          </Box>
          <Paper className='chip_container'>
            <Typography>위치</Typography>
            {saveAuthRegionLimit !== null &&
              saveAuthRegionLimit.length > 0 &&
              saveAuthRegionLimit.map((loc: any, idx: number) => (
                <NoneBorderChip
                  label={`${getRegionAmdCode(loc.amd)} ${splitLocaitonLimit(
                    loc.location
                  )}`}
                  variant='outlined'
                  onDelete={() => deleteAuthRegion(idx, 'authRegionLimit')}
                  key={idx.toString()}
                  sx={{
                    m: '.5em',
                    border: 'none',
                    '& .css-6od3lo-MuiChip-label': {
                      paddingRight: '6px',
                    },
                  }}
                />
              ))}
            <div className='flex_end'>
              <Button
                onClick={() => deleteAllAuthRegion()}
                color='info'
                sx={{ fontSize: '.8rem' }}
              >
                <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
                전체삭제
              </Button>
            </div>
          </Paper>
          <Divider />
        </Box>
        <Box>
          <Box className='flex_start' sx={{ mb: '1rem' }}>
            <Typography sx={{ mr: '.4rem' }}>주소 제한 (사용안함)</Typography>
            <Tooltip
              title={
                <Typography fontSize={TOOLTIP_FONT_SIZE}>
                  {TOOLTIP_COMMUNITY_ADDRESS_AUTH}
                </Typography>
              }
              followCursor
            >
              <HelpOutlineIcon sx={{ color: '#999999' }} />
            </Tooltip>
          </Box>
          <Box>
            <SwSelectLocation
              limitKu={true}
              includeAmd={true}
              selectOne={true}
              showButton={false}
              showDepth1={false}
              location={authAddressLimit}
              setLocation={setAuthAddressLimit}
              showContainer={false}
              mapId='authAddress_map'
            />
          </Box>
          <Paper className='chip_container'>
            <Typography>지역</Typography>
            {saveAuthAddressLimit !== null &&
              saveAuthAddressLimit.length > 0 &&
              saveAuthAddressLimit.map((loc: any, idx: number) => (
                <NoneBorderChip
                  label={`${getRegionAmdCode(loc.amd)} ${splitLocaitonLimit(
                    loc.location
                  )}`}
                  variant='outlined'
                  onDelete={() => deleteAuthAddress(idx, 'authAddressLimit')}
                  key={idx.toString()}
                  sx={{
                    m: '.5em',
                    border: 'none',
                    '& .css-6od3lo-MuiChip-label': {
                      paddingRight: '6px',
                    },
                  }}
                />
              ))}
            <div className='flex_end'>
              <Button
                onClick={() => deleteAllAuthAddress()}
                color='info'
                sx={{ fontSize: '.8rem' }}
              >
                <DeleteOutlineIcon sx={{ fontSize: '1.2rem' }} />
                전체삭제
              </Button>
            </div>
          </Paper>
        </Box>
      </CreatePaper>
      {location.pathname.includes('create') ? (
        <Box sx={{ p: '1rem 0 7rem' }} className='flex_between'>
          <Button
            variant='contained'
            color='secondary'
            sx={{ mr: '1rem' }}
            onClick={() => {
              window.location.reload();
            }}
          >
            신규작성
          </Button>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              communityType === 0 ? setOpenConfirm(true) : isSending(0);
            }}
          >
            저장
          </Button>
        </Box>
      ) : (
        <Box sx={{ p: '1rem 0 7rem' }} className='flex_end'>
          <Button
            variant='contained'
            color='info'
            onClick={() => {
              communityType === 0 ? setOpenConfirm(true) : isSending(1);
            }}
          >
            저장
          </Button>
        </Box>
      )}
      <SwSnackbar
        open={false}
        onClose={() => setOpenDialog(false)}
        contents={dialogMessage}
      />
      <SwAlert
        open={openDialog}
        onConfirm={() => setOpenDialog(false)}
        title={dialogTitle}
        contents={dialogMessage}
      />
      {openMore === 1 && (
        <Dialog
          open={!!openMore}
          sx={{
            '& .MuiDialog-container': {
              height: '85%',
            },
          }}
        >
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogContent sx={{ p: '1rem 2rem' }}>
            <Typography sx={{ mt: '0.7rem' }}>{dialogMessage}</Typography>
          </DialogContent>
          <Divider sx={{ p: 0, m: 0 }} />
          <DialogActions>
            <Button
              color='info'
              fullWidth
              size='small'
              onClick={() => setOpenMore(0)}
            >
              취소
            </Button>
            <Button
              color='primary'
              fullWidth
              size='small'
              onClick={() => checkCommunity()}
            >
              확인
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <SwConfirmDialog
        contents='커뮤니티 `공개형`으로 개설합니다.'
        open={openConfirm}
        onClose={() => setOpenConfirm(false)}
        onConfirm={() => {
          location.pathname.includes('create') ? isSending(0) : isSending(1);
          setOpenConfirm(false);
        }}
        confirm={1}
      />
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={dialogMessage}
      />
    </>
  );
};
