import axios from 'axios';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import React, {
  Dispatch,
  ChangeEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { QueryCache, useMutation, useQuery, useQueryClient } from 'react-query';
import clsx from 'clsx';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import SearchIcon from '@mui/icons-material/Search';
import { TableTitle } from '../../commonComponent/tableElement/TableTitle';
import {
  IGuideTool,
  IChallengeCreate,
  IChallengeReqeust,
  DefaultChallengeCreate,
} from '../../../models/challenge';
import {
  changeChallengeAttendContent,
  downloadChallenge,
  downloadChallengeAttendUser,
  getChallengeResultList,
} from '../../../common/api/ApiChallenge';
import {
  KEY_CHALLENGE_RESULT_DOWNLOAD,
  KEY_CHALLENGE_RESULT_LIST,
} from '../../../common/key';
import {
  encodeSearchWord,
  timestamp2Localestring,
} from '../../../common/helper';
import { SwPagination } from '../../commonComponent/tableElement/SwPagination';
import { xapi } from '../../../hooks/session';
import SwLoading from '../../commonComponent/spinner/SwLoading';
import { SwSnackbar } from '../../commonComponent/views/SwSnackbar';
import { SwLoadAttendUser } from '../../commonComponent/SwLoadAttendUser';
import { SwLoadDeleteAttendUser } from '../../commonComponent/SwLoadDeleteAttendUser';

const columnsType1 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '걸음 수',
  '응모 횟수',
  '쿠폰 발급 수',
];
const columnsType4 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '달성 퍼센트',
  '응모 횟수',
  '쿠폰 발급 수',
];
const columnsType5 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '걸음 수',
];
const columnsType6 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '총 마일리지',
  '남은 마일리지',
  '응모 횟수',
  '쿠폰 발급 수',
];
const columnsType7 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '획득 스탬프 수',
  '응모 횟수',
  '쿠폰 발급 수',
];
const columnsType9 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '완주 횟수',
  '응모 횟수',
  '쿠폰 발급 수',
];

const columnsType12 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '걸음 수',
  '인증 횟수',
  '응모 횟수',
  '쿠폰 발급 수',
];

const columnsType16 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '걸음 수',
  '청취 컨텐츠 수',
  '응모 횟수',
  '쿠폰 발급 수',
];

const columnsType17 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '총 마일리지',
  '남은 마일리지',
  '걸음 마일리지',
  '캐릭터 마일리지',
  '응모 횟수',
  '쿠폰 발급 수',
];

const columnsType18 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '총 마일리지',
  '남은 마일리지',
  '걸음 마일리지',
  '캐릭터 마일리지',
  '응모 횟수',
  '쿠폰 발급 수',
];

const columnsType19 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '청취 컨텐츠 수',
  '완주 횟수',
  '응모 횟수',
  '쿠폰 발급 수',
];

const columnsType20 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '청취 컨텐츠 수',
  '획득 스탬프 수',
  '응모 횟수',
  '쿠폰 발급 수',
];

const columnsType21 = [
  '닉네임',
  '커뮤니티 닉네임 사용 여부',
  '커뮤니티 닉네임',
  '아이디',
  '연락처',
  '거주지역',
  '활동지역',
  '참여 일시',
  '청취 컨텐츠 수',
  '달성 퍼센트',
  '응모 횟수',
  '쿠폰 발급 수',
];

interface Props {
  challengeInfo: any;
  setChallengeInfo: Dispatch<React.SetStateAction<any>>;
  reloadChallenge: (id: string) => void;
}

export const ChallengeResult: React.FC<Props> = ({
  challengeInfo,
  setChallengeInfo,
  reloadChallenge,
}) => {
  const queryClient = useQueryClient();
  const resultInputRef = useRef<any>();
  const [resultFilter, setResultFilter] = useState<string>('');
  const [requestList, setRequestList] = useState<any>([]);
  const [resultInfo, setResultInfo] = useState<any>();
  const [challengeId, setChallengeId] = useState<string>('');
  const [columns, setColumns] = useState<string[]>([]);
  const [activeCount, setActiveCount] = useState(0);
  const rowsPerPageOptions = [5, 10, 15, 20];
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchKey, setSearchKey] = useState<string>('challenge');
  const [searchWord, setSearchWord] = useState<string>('');
  const [dailyStatType, setDailyStatType] = useState<any>(0);
  const [loading, setLoading] = useState<any>(false);
  const [downloadType, setDownloadType] = useState<any>('');
  const [alertMessage, setAlertMessage] = useState<any>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [openAttendUser, setOpenAttendUser] = useState<number>(0);
  const [openDeleteAttendUser, setOpenDeleteAttendUser] = useState<number>(0);

  const processResult = (result: any) => {
    if (result) {
      const newRequestList = result.result.map((item: any) => {
        console.log('-> result:', item);
        const newDate = timestamp2Localestring(item.attendTime, 1000);

        var newResult: any[] = [];
        if (result.type === 1 || (result.type === 18 && result.subType === 1)) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.remainPoint,
            item.rewardDrawCount,
            item.rewardCount,
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(columnsType1);
        } else if (result.type === 5) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.progress,
            item.rewardDrawCount,
            item.rewardCount,
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(columnsType5);
        } else if (result.type === 6 || result.type === 11) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.remainPoint, // 총마일리지
            item.remainPoint - item.remainGetPoint, // 남은마일리지
            item.rewardDrawCount, // 응모횟수
            item.rewardCount, // 쿠폰발급수
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(columnsType6);
        } else if (
          result.type === 7 ||
          (result.type === 18 && result.subType === 3)
        ) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.progress,
            item.rewardDrawCount,
            item.rewardCount,
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(columnsType7);
        } else if (result.type === 4 || result.type === 9) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.progress ? item.progress : 0,
            item.rewardDrawCount,
            item.rewardCount,
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          if (result.type === 4) setColumns(columnsType4);
          else setColumns(columnsType9);
        } else if (
          result.type === 12 ||
          result.type === 13 ||
          result.type === 14 ||
          result.type === 15 ||
          (result.type === 18 && result.subType === 2)
        ) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.optionalProgress,
            item.progress,
            item.rewardDrawCount,
            item.rewardCount,
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(columnsType12);
        } else if (result.type === 16) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.progress,
            item.completedAudioContentCount,
            item.rewardDrawCount,
            item.rewardCount,
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(columnsType16);
        } else if (result.type === 17) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.stepGetPoint + item.characterGetPoint, // 총마일리지 = 걸음수 + 캘릭터 잡기 마일리지
            item.stepGetPoint + item.characterGetPoint - item.remainGetPoint, // 남은마일리지
            item.stepGetPoint, // 걸음 마일리지
            item.characterGetPoint, // 캐릭터 마일리지
            item.rewardDrawCount, // 응모횟수
            item.rewardCount, // 쿠폰발급수
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(columnsType17);
        } else if (
          result.type === 19 ||
          result.type === 20 ||
          result.type === 21
        ) {
          newResult = [
            item.name,
            item.nickName === null ? '-' : item.useNickName === 1 ? 'O' : '-',
            item.nickName !== null ? item.nickName : '-',
            item.userId,
            item.phoneno,
            item.residenceAddress !== null ? item.residenceAddress : '-',
            item.activityAddress !== null ? item.activityAddress : '-',
            newDate,
            item.optionalProgress,
            item.progress,
            item.rewardDrawCount, // 응모횟수
            item.rewardCount, // 쿠폰발급수
            item.residenceAuthYN,
            item.activityAuthYN,
          ];
          setColumns(
            result.type === 19
              ? columnsType19
              : result.type === 20
              ? columnsType20
              : columnsType21
          );
        }

        return newResult;
      });
      return newRequestList;
    }
    return [];
  };

  const {
    data,
    isLoading,
    refetch: challengeResultdRefetch,
  } = useQuery(
    KEY_CHALLENGE_RESULT_LIST,
    () => {
      const newData = {
        challengeId: challengeId,
        page: page,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      if (challengeId.length > 0) return getChallengeResultList(newData);
      return null;
    },
    {
      onSuccess: res => {
        console.log(res);
        if (res && res.result) {
          setRequestList(processResult(res.result));
          setResultInfo(res.result);
          setTotalRecords(res.pagination.totalRecordCount);
          setActiveCount(res.result.activeCount);
        }
        setLoading(false);
      },
      onError: e => {
        console.log(e);
        setLoading(false);
      },
    }
  );

  const resultPrefetch = (newPage: number) => {
    queryClient.prefetchQuery(KEY_CHALLENGE_RESULT_LIST, () => {
      const newData = {
        challengeId: challengeId,
        page: newPage,
        rowsPerPage: rowsPerPage,
        searchKey: searchKey,
        searchWord: encodeSearchWord(searchKey, searchWord),
      };
      getChallengeResultList(newData);
    });
  };

  const downloadChallengeLocal = (param: any) =>
    xapi
      .get(
        `/admin/v2/apis/challenge/download/${param.downloadType}?challengeId=${param.challengeId}&type=${param.type}&searchKey=${param.searchKey}&searchWord=${param.searchWord}&startDate=${param.startDate}&endDate=${param.endDate}`,
        { responseType: 'blob' }
      )
      .then(res => {
        const blob = new Blob([res.data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `challenge_${param.downloadType}.xlsx`;
        document.body.appendChild(a);
        a.click();
        setTimeout((_: any) => {
          window.URL.revokeObjectURL(url);
        }, 600000);
        a.remove();
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error('err: ', err);
      });

  const downloadChallengeData = (type: string) => {
    const newData = {
      downloadType: type,
      challengeId: challengeId,
      type: '',
      searchKey: 'challenge',
      searchWord: resultFilter,
      startDate: 0,
      endDate: 0,
    };
    setLoading(true);
    downloadChallengeLocal(newData);
  };

  const {
    mutate: changeChallengeAttendContentMutation,
    isError: isChangeMutateError,
  } = useMutation(changeChallengeAttendContent, {
    onSuccess: () => {
      setOpenSnackbar(true);
      setAlertMessage(`챌린지 콘텐츠 수정을 요청했습니다.`);
    },
    onError: error => {
      setOpenSnackbar(true);
      setAlertMessage(
        `챌린지 콘텐츠 수정하는 동안 오류가 발생했습니다. 다시 시도해 주시기 바랍니다.`
      );
    },
  });

  const updateChallengeContent = () => {
    const newData = {
      challengeId: challengeId,
    };
    changeChallengeAttendContentMutation(newData);
  };

  const handleChangePage = (_event: any, newPage: number) => {
    setPage(newPage);
  };

  const searchChallenge = () => {
    if (page === 1) {
      setLoading(true);
      challengeResultdRefetch();
    } else {
      setPage(1);
    }
  };

  const downloadStat = (type: number) => {
    console.log('downloadType: ', type);
    type === 1
      ? downloadChallengeData('challenge-daily-step')
      : type === 2
      ? downloadChallengeData('challenge-content-step')
      : type === 3
      ? downloadChallengeData('challenge-daily-step-adv')
      : '';
  };

  const downloadStampStat = (type: number) => {
    const includeTime = type === 1 ? 0 : type === 2 ? 1 : type === 3 ? 2 : 0;
    const newData = {
      challengeId: challengeId,
      includeTime: includeTime,
    };
    setLoading(true);
    downloadChallengeAttendUser(newData, setLoading);
  };

  const downloadAttendUser = () => {
    const newData = {
      challengeId: challengeId,
      includeTime: 0,
    };
    setLoading(true);
    downloadChallengeAttendUser(newData, setLoading);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      searchChallenge();
    }
  };

  const checkAttendUser = () => {
    setOpenAttendUser(1);
  };

  const checkAttendUserChangeStep = () => {
    setOpenAttendUser(2);
  };

  useEffect(() => {
    // const newRequestList = processResult(resultInfo);
    // const newResult = newRequestList.filter((item: any) => {
    //   if (
    //     item[0].includes(resultFilter) ||
    //     item[1].includes(resultFilter) ||
    //     item[2].includes(resultFilter)
    //   )
    //     return true;
    //   return false;
    // });
    // setRequestList(newResult);
    setSearchWord(resultFilter);
  }, [resultFilter]);

  useEffect(() => {
    if (challengeId.length > 0) {
      setLoading(true);
      challengeResultdRefetch();
    }
  }, [page]);

  useEffect(() => {
    if (challengeId.length > 0) {
      setLoading(true);
      challengeResultdRefetch();
    }
  }, [challengeId]);

  useEffect(() => {
    console.log('ChallengeResult challengeInfo :', challengeInfo);
    if (challengeInfo) {
      setPage(1);
      setResultFilter('');
      setChallengeId(challengeInfo.challengeId);
    }
  }, [challengeInfo]);

  return (
    <>
      <Paper sx={{ p: '1.5rem' }}>
        <Typography fontSize='1rem' fontWeight={500}>
          챌린지 결과
        </Typography>
        <Box className='flex_between' sx={{ p: '1rem 0' }}>
          <TextField
            sx={{ width: '24rem' }}
            placeholder='닉네임, 아이디, 연락처로 검색해 주세요'
            inputRef={resultInputRef}
            value={resultFilter}
            onChange={evt => setResultFilter(evt.target.value)}
            onKeyDown={handleKeyDown}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton sx={{ pr: 0 }} onClick={searchChallenge}>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Box className='flex_end'>
            <Box sx={{ mr: '1rem' }}>
              <TextField
                disabled={loading}
                select
                sx={{ width: '17rem' }}
                value={dailyStatType}
                onChange={(
                  event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                ) => {
                  downloadStat(Number(event.target.value));
                }}
              >
                <MenuItem key={0} value={0}>
                  일자별 통계 다운로드 선택
                </MenuItem>
                <MenuItem key={1} value={1}>
                  참여자 일일 걸음 수 다운로드
                </MenuItem>
                <MenuItem key={2} value={2}>
                  참여자 챌린지 걸음 수 다운로드
                </MenuItem>
                <MenuItem key={3} value={3}>
                  참여자 일일 걸음 수(Adv) 다운로드
                </MenuItem>
              </TextField>
            </Box>
            <Button
              disabled={loading}
              sx={{ mr: '1rem' }}
              color='info'
              variant='outlined'
              onClick={() => {
                downloadChallengeData('challenge-result');
              }}
            >
              챌린지 결과 다운로드
              <FileDownloadRoundedIcon color='success' />
            </Button>
            {/* <Button
            color='info'
            variant='outlined'
            onClick={() => {
              updateChallengeContent();
            }}
          >
            챌린지 걸음수 보정
          </Button> */}
            {challengeInfo && challengeInfo.challengeType === 7 && (
              <Box sx={{ mr: '1rem' }}>
                <TextField
                  disabled={loading}
                  select
                  sx={{ width: '17rem' }}
                  value={dailyStatType}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
                  ) => {
                    downloadStampStat(Number(event.target.value));
                  }}
                >
                  <MenuItem key={0} value={0}>
                    스탬프 챌린지 참여자 다운로드 선택
                  </MenuItem>
                  <MenuItem key={1} value={1}>
                    스탬프 챌린지 참여자 다운로드
                  </MenuItem>
                  <MenuItem key={2} value={2}>
                    스탬프 챌린지 참여자 다운로드(시간포함)
                  </MenuItem>
                  <MenuItem key={3} value={3}>
                    스탬프 챌린지 참여자 다운로드(스탬프목록제외)
                  </MenuItem>
                </TextField>
              </Box>
            )}
            <Button
              color='info'
              variant='outlined'
              onClick={() => checkAttendUser()}
            >
              참여자 추가
            </Button>
            <Button
              sx={{ ml: '.5rem' }}
              color='info'
              variant='outlined'
              onClick={() => setOpenDeleteAttendUser(1)}
            >
              참여자 삭제
            </Button>
            <Button
              sx={{ ml: '1rem' }}
              color='info'
              variant='outlined'
              onClick={() => checkAttendUserChangeStep()}
            >
              참여자 걸음수 보정
            </Button>
          </Box>
        </Box>
        <Paper sx={{ p: '1rem' }}>
          <TableTitle title='참여자(탈퇴제외)' count={activeCount} />
          <TableContainer sx={{ height: '40vh', overflowY: 'scroll' }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column: any, idx: number) => (
                    <TableCell key={idx.toString()} sx={{ p: '.4rem .7rem' }}>
                      {column}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {requestList.length > 0 &&
                  requestList.map((row: any, idx: number) => (
                    <TableRow key={idx.toString()}>
                      {columns.map((column: any, i: number) =>
                        i === 5 ? (
                          <TableCell
                            className={
                              resultInfo.result[idx].deleted === 1
                                ? clsx('not_important_row')
                                : ''
                            }
                            key={i.toString()}
                            sx={{
                              p: '.7rem',
                              color:
                                row[columns.length] === 'Y'
                                  ? 'blue'
                                  : row[i] !== '-'
                                  ? 'red'
                                  : '',
                            }}
                          >
                            {row[i]}
                          </TableCell>
                        ) : i === 6 ? (
                          <TableCell
                            className={
                              resultInfo.result[idx].deleted === 1
                                ? clsx('not_important_row')
                                : ''
                            }
                            key={i.toString()}
                            sx={{
                              p: '.7rem',
                              color:
                                row[columns.length + 1] === 'Y'
                                  ? 'blue'
                                  : row[i] !== '-'
                                  ? 'red'
                                  : '',
                            }}
                          >
                            {row[i]}
                          </TableCell>
                        ) : (
                          <TableCell
                            className={
                              resultInfo.result[idx].deleted === 1
                                ? clsx('not_important_row')
                                : ''
                            }
                            key={i.toString()}
                            sx={{ p: '.7rem' }}
                          >
                            {row[i]}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <SwPagination
            page={page}
            handleChangePage={handleChangePage}
            count={Math.ceil(totalRecords / rowsPerPage)}
          />
        </Paper>
      </Paper>
      <SwSnackbar
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        contents={alertMessage}
      />
      <SwLoadAttendUser
        challengeId={challengeId}
        attendType={openAttendUser}
        open={openAttendUser !== 0}
        onclose={() => {
          setOpenAttendUser(0);
          challengeResultdRefetch();
          reloadChallenge(challengeId);
        }}
      />
      <SwLoadDeleteAttendUser
        challengeId={challengeId}
        attendType={openDeleteAttendUser}
        open={openDeleteAttendUser !== 0}
        onclose={() => {
          setOpenDeleteAttendUser(0);
          challengeResultdRefetch();
          reloadChallenge(challengeId);
        }}
      />
      {loading && <SwLoading />}
    </>
  );
};
